import React from "react";
const Label = ({ children, className, star }) => {
  return (
    <>
      <p
        className={`text-[#1b254b] font-medium mb-[0.5rem] text-[0.875rem] cursor-default ${className}`}
      >
        {children}
        <span className="text-[#fe4160]">{star}</span>
      </p>
    </>
  );
};
export default Label;
