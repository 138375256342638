import Layout from "layout";
import Searchbar from "../../components/Searchbar";
import UserTable from "./components/UserTable";
import { URL } from "constants";
import { useEffect } from "react";
import { useUserContext } from "context/index.js";

const Users = () => {
  const { userData, setUserData, onGetAllUser, setLoading } = useUserContext();
  
  useEffect(() => {
    onGetAllUser();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (value) => {
    if (value === "") {
      setUserData({ ...userData, filterData: userData.data });
    } else {
      const updatedList = userData.data.filter((x) => {
        const username = x.username ? x.username.toLowerCase() : "";
        const id = x.id ? x.id.toLowerCase() : "";
        const status = x.status ? x.status.toLowerCase() : "";

        return (
          username.includes(value.toLowerCase()) ||
          id.includes(value.toLowerCase()) ||
          status.includes(value.toLowerCase())
        );
      });
      setUserData({ ...userData, filterData: updatedList });
    }
  };

  return (
    <Layout route={URL.USERS.BASE}>
      <Searchbar onSearchChange={handleSearchChange} />
      <UserTable
        filterData={userData.filterData}
      />
    </Layout>
  );
};

export default Users;
