import React from "react";
const Input = ({ placeholder, type, changeColor, name, value, minLength }) => {
  return (
    <>
      <input
        name={name}
        value={value}
        onChange={changeColor}
        className={`editInp w-full font-medium text-[0.875rem] h-[3rem] text-[#1b254b] bg-transparent pl-4 relative`}
        minLength={minLength}
        placeholder={placeholder}
        type={type}
      />
    </>
  );
};
export default Input;
