export const ORDER_COLUMNS = [
  {
    id: "User Name",
    label: "User Name",
    minWidth: 130,
  },
  {
    id: "date",
    label: "Date",
    minWidth: 150,
    align: "center",
  },
  {
    id: "Status",
    label: "Status",
    minWidth: 170,
    align: "center",
  },
  {
    id: "total amount",
    label: "Total Amount",
    minWidth: 170,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    align: "center",
    minWidth: 170,
  },
];
