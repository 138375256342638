import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "@mui/material";
import { useUserContext } from "context";
import { deleteOrderByIdEndPoint } from "api/endpoints/order";
import { HTTP_STATUS_CODES } from "constants";
import { toastError, toastSuccess } from "api/integration/toast";
import { useNavigate } from "react-router-dom";
import { URL } from "constants";

const DeleteOrderModal = ({ setShowOrderDetailDeleteModal, orderDetail }) => {
  const { token, onGetAllOrders, setLoading } = useUserContext();
  const navigate = useNavigate()

  const onDeleteOrder = async (id) => {
    try {
      const response = await deleteOrderByIdEndPoint(token, id);
      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        setShowOrderDetailDeleteModal(false)
        setLoading(true);
        onGetAllOrders();
        toastSuccess("Delete Order Successfully");
        navigate(URL.ORDERS.BASE)
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  return (
    <div className="fixed top-0 left-0 bg-black bg-opacity-25 min-h-screen min-w-screen flex items-center justify-center z-50 my-auto overflow-y-auto py-5 w-full h-full">
      <div className="bg-white rounded-lg pt-8 pb-6 px-8 relative w-5/12 flex flex-col items-center gap-8 z-20 m-auto">
        <CancelIcon
          onClick={() => setShowOrderDetailDeleteModal(false)}
          sx={{
            position: "absolute",
            top: "5px",
            right: "5px",
            color: "#ff2f60",
            cursor: "pointer",
          }}
        />
        <h1 className="text-2xl">Delete Order</h1>
        <div className="flex items-center gap-x-5 w-full">
          <Button
            onClick={() => onDeleteOrder(orderDetail.id)}
            sx={{
              width: "100%",
              color: "white",
              border: "1px solid #ff2f60",
              backgroundColor: "#ff2f60",
              "&:hover": {
                border: "1px solid #ff2f60",
                backgroundColor: "#ff2f60",
              },
            }}
            variant="outline"
          >
            Yes
          </Button>
          <Button
            onClick={() => setShowOrderDetailDeleteModal(false)}
            sx={{
              width: "100%",
              color: "#ff2f60",
              border: "1px solid #ff2f60",
              "&:hover": {
                border: "1px solid #ff2f60",
              },
            }}
            variant="outline"
          >
            No
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteOrderModal;
