import Dashboard from "views/Dashboard";
import Toaster from "components/Toaster";
import { URL } from "constants/index";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "views/Login";
import Users from "views/Users";
import Product from "views/Product";
import Brands from "views/Brands";
import ProductSetting from "views/ProductSetting";
// import Banners from "views/Banners";
import Orders from "views/Orders";
import SubCategory from "views/SubCategory";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import NotPageFound from "views/NotFoundPage";
import Wrong from "views/Wrong";
import Final from "views/Final";
import OrdersDetail from "views/Orders/components/OrdersDetail";
import { useUserContext } from "context";
import Loader from "components/Loader";
import ContactUs from "views/ContactUs";

const MainRouter = () => {
  const { loading } = useUserContext();

  return (
    <>
      {loading && <Loader />}
      <Routes>
        {/* Private Routes */}
        <Route path={URL.DASHBOARD.BASE} element={<PrivateRoute />}>
          <Route path={URL.DASHBOARD.BASE} element={<Dashboard />} />
          <Route path={URL.USERS.BASE} element={<Users />} />
          <Route path={URL.PRODUCTS.BASE} element={<Product />} />
          <Route path={URL.BRANDS.BASE} element={<Brands />} />
          <Route path={URL.SETTING.BASE} element={<ProductSetting />} />
          {/* <Route path={URL.BANNERS.BASE} element={<Banners />}/> */}
          <Route path={URL.ORDERS.BASE} element={<Orders />} />
          <Route path={URL.ORDERSDETAIL.BASE} element={<OrdersDetail />} />
          <Route path={URL.SUBCATEGORY.BASE} element={<SubCategory />} />
          <Route path={URL.WRONG.BASE} element={<Wrong />} />
          <Route path={URL.FINAL.BASE} element={<Final />} />
          <Route path={URL.CONTACT_US.BASE} element={<ContactUs />} />
        </Route>
        {/* Public Routes */}
        <Route path={URL.HOME.BASE} element={<PublicRoute />}>
          <Route path={URL.HOME.BASE} element={<Login />} />
        </Route>
        <Route path={URL.NOT_FOUND_PAGE} element={<NotPageFound />} />
      </Routes>

      <Toaster />
    </>
  );
};

export default MainRouter;
