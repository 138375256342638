import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { FormControl, TextField } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const AddSubCategoryModal = ({
  onClose,
  open,
  isEditing,
  onCreateSubCategory,
  onUpdateSubCategory,
  categoryName,
  setCategoryName,
  setIsEditing,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "520px",
            borderRadius: "15px",
          },
          "&::-webkit-scrollbar": {
            width: 4,
            height: 4,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "white",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#FF1D52",
            borderRadius: 2,
          },
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          background: "#ff1d52",
          color: "white",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor:"pointer"
        }}
      >
        {isEditing ? "Edit SubCategory" : "Create New SubCategory"}
        <DialogActions>
          <CloseIcon onClick={onClose} sx={{ fontSize: "18px" }} />
        </DialogActions>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <FormControl
            sx={{
              width: "100%",
              marginTop: 4,
            }}
            size="small"
          >
            <TextField
              id="outlined-basic"
              label="SubCategory Name"
              variant="outlined"
              size="small"
              margin="normal"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
            {isEditing ? (
              <Button
                onClick={onUpdateSubCategory}
                sx={{
                  background: "#FF1D52",
                  color: "white",
                  marginTop: "20px",
                  "&:hover": {
                    background: "#FF005C",
                  },
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                onClick={onCreateSubCategory}
                sx={{
                  background: "#FF1D52",
                  color: "white",
                  marginTop: "20px",
                  "&:hover": {
                    background: "#FF005C",
                  },
                }}
              >
                Create
              </Button>
            )}
          </FormControl>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default AddSubCategoryModal;
