import React from "react";
import { capitalizeString } from "api/integration/functions";
import ToastWrapper from "../ToastWrapper/index";
import { useNavigate } from "react-router-dom";

const ToastNotification = ({ message, title, url, type }) => {
  const navigate = useNavigate();

  return (
    <>
      <ToastWrapper>
        <div onClick={() => (url ? navigate(url) : null)}>
          <div className="text-[14.5px] mt-[2px]">
            {typeof message === "string"
              ? capitalizeString(message)
              : "Internal error"}
          </div>
        </div>
      </ToastWrapper>
    </>
  );
};

export default ToastNotification;
