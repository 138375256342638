import { capitalizeString } from "api/integration/functions";
import React from "react";

const Tag = ({ tag }) => {
  return (
    <>
      {tag.map((tagItem, i) => (
        <span
          className="min-w-[60px] text-center bg-[#ff1d52] p-2 text-white rounded-md text-sm capitalize"
          key={i}
        >
          {capitalizeString(tagItem.trim())}
        </span>
      ))}
    </>
  );
};

export default Tag;
