// export const capitalizeString = (text) => {
//   return text && text.length > 2
//     ? text.charAt(0).toUpperCase() + text.slice(1)
//     : "";
// };
export const capitalizeString = (text) => {
  if (!text) {
    return "";
  }

  const words = text.split(" ");
  const capitalizedWords = words.map((word) => {
    const lowercaseWord = word.toLowerCase();
    return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
  });

  return capitalizedWords.join(" ");
};
