export const BRAND_COLUMNS = [
  { id: "id", label: "ID", minWidth: 100 },
  { id: "name", label: "Brand Name", minWidth: 170 },
  {
    id: "logo",
    label: "Logo",
    minWidth: 170,
    align: "left",
  },
  {
    id: "thumbnail",
    label: "Thumbnail",
    minWidth: 170,
    align: "left",
  },
  {
    id: "delivery_charges",
    label: "Delivery Charges",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "delivery_offer",
    label: "Delivery Offer",
    minWidth: 170,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
  },
  {
    id: "createdAt",
    label: "Date",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "banner_desktop",
    label: "Banner",
    minWidth: 170,
    align: "left",
  },
  {
    id: "banner_mobile",
    label: "Mobile Banner",
    minWidth: 170,
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];
