export const SUB_CATEGORY_COLUMNS = [
  {
    id: "id",
    label: "ID",
    minWidth: 100,
  },
  {
    id: "name",
    label: "Name",
    minWidth: 170,
  },
  {
    id: "createdAt",
    label: "Created At",
    minWidth: 170,
    align: "left",
  },
  {
    id: "updatedAt",
    label: "Updated At",
    minWidth: 170,
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];
