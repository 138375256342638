import React, { useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import { deleteUserEndPoint } from "api/endpoints/user";
import { HTTP_STATUS_CODES } from "constants";
import { toastError, toastSuccess } from "api/integration/toast";
import { useUserContext } from "context";
import ActionDropdown from "views/ContactUs/components/ActionDropDown";
import { FirstLetterCapital } from "utils/firstLetterCapital";

const ContactTableRow = ({ row, handleOpenModal }) => {
  const { token, onGetAllUser, setLoading } = useUserContext();

  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const onDeleteUser = async () => {
    try {
      const response = await deleteUserEndPoint(token, row.id);
      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        setLoading(true);
        onGetAllUser();
        toastSuccess("Action Performed Successfully");
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        onClick={() => handleOpenModal(row)}
        sx={{ cursor: "pointer" }}
      >
        <TableCell sx={{ fontWeight: "700" }}>{row.id.substring(18)}</TableCell>
        <TableCell>{row.user_id?.username ?? row.userDetails?.name ?? "No Name"}</TableCell>
        <TableCell>{row.user_id?.phone_number ?? row.userDetails?.phone_number ?? "No Number"}</TableCell>
        <TableCell className="max-w-[150px]">
          <p className="w-full truncate">{row.subject}</p>
        </TableCell>
        <TableCell className="max-w-[200px]">
          <p className="w-full truncate">{row.message}</p>
        </TableCell>
        <TableCell className="max-w-[200px]">
          <p className="w-full truncate">
            {row && row.status ? FirstLetterCapital(row.status) : ""}
          </p>
        </TableCell>
        <TableCell onClick={(event) => event.stopPropagation()}>
          <ActionDropdown
            openModal={openModal}
            setOpenModal={setOpenModal}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            onDeleteUser={onDeleteUser}
            row={row}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default ContactTableRow;
