import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const Searchbar = ({ onSearchChange }) => {
  const [value, setValue] = useState("");

  const handleButtonClick = (event) => {
    event.preventDefault();
    onSearchChange(value);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="flex mt-3">
      <form action="" className="relative w-full">
        <input
          value={value}
          onChange={handleInputChange}
          type="search"
          className="block py-2 px-3 w-full text-sm bg-gray-50 focus:outline-none rounded-r-lg rounded-md border border-gray-300 focus:ring-[#FF2F60] placeholder-gray-400 focus:border-pink-500"
          placeholder="Search"
        />
        <button
          onClick={handleButtonClick}
          type="submit"
          className="absolute top-0 right-0 py-2 px-3 text-sm font-medium text-white bg-[#FF2F60] rounded-r-lg border border-[#FF2F60] hover:bg-[#FF2F60] focus:ring-4 focus:outline-none focus:ring-[#FF2F60] dark:bg-[#FF2F60] dark:hover:bg-[#FF2F60] dark:focus:ring-[#FF2F60]"
        >
          <SearchIcon sx={{ fontSize: "18px" }} />
        </button>
      </form>
    </div>
  );
};

export default Searchbar;
