import React from "react";

const DashboardCard = ({ value, title, icon }) => {
  return (
    <div className="bg-white rounded-3xl shadow-md px-5 py-4 flex  items-center justify-start gap-5 capitalize ">
      <div className=" bg-[#F4F7FE] rounded-full h-[56px] w-[56px] flex items-center justify-center">
        {icon}
      </div>
      <div>
        <div className="text-sm text-[#A3AED0] font-medium ">{title}</div>
        <div className="text-2xl font-bold text-black">{value}</div>
      </div>
    </div>
  );
};

export default DashboardCard;
