import React, { Fragment } from "react";
import { TableCell, TableRow } from "@mui/material";
import { URL } from "constants";
import { useNavigate } from "react-router-dom";
import { dateFormatter } from "utils/numberFormatter/date";
import { useUserContext } from "context";
import { MODAL_STATUS } from "constants";

const OrderTableRow = ({ row }) => {
  const navigate = useNavigate();
  const { setModalAndState } = useUserContext()

  const handleRowClick = () => {
    navigate(URL.ORDERSDETAIL.BASE.replace(":id", row.id));
  };

  return (
    <Fragment key={row.id}>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
        <TableCell
          sx={{ textAlign: "left" }}
        >
          {row.address?.name ?? "No Name"}
        </TableCell>
        <React.Fragment></React.Fragment>
        <TableCell sx={{ textAlign: "center" }}>
          {dateFormatter(row.createdAt)}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>{row.orderStatus?.isActive ? "Active" : "Cancelled"}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>Rs. {row.amount}</TableCell>
        <TableCell
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            onClick={() => setModalAndState({ modal: MODAL_STATUS.CHANGE_ORDER_STATUS, state: row })}
            className="button-con font-normal w-20 cursor-pointer mx-3"
          >
            <p className={`hover:-translate-y-1 hover:shadow-btnShadow transition-all duration-500 p-2 rounded-lg hover:opacity-60 flex justify-center items-start max-w-full border border-[#ff1d52]  ${row.orderStatus?.isActive ? "text-[#ff1d52] bg-white" : "bg-[#ff1d52] text-white"}`}>
              {row.orderStatus?.isActive ? "Cancel" : "Activate"}
            </p>
          </div>
          <div
            onClick={() => handleRowClick()}
            className="button-con font-normal w-16 cursor-pointer"
          >
            <p className="hover:-translate-y-1 hover:shadow-btnShadow transition-all duration-500 p-2 px-4 rounded-lg hover:opacity-60 flex justify-center items-start max-w-full  bg-[#ff1d52] text-white">
              View
            </p>
          </div>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default OrderTableRow;
