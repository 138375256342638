import Layout from "layout";
import React, { useEffect, useState } from "react";
import { dateFormatter } from "utils/numberFormatter/date";
import { URL } from "../../../../constants/index";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "context";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FirstLetterCapital } from "utils/firstLetterCapital";
import EditIcon from "@mui/icons-material/Edit";
import EditOrderModal from "modal/EditOrderModal";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOrderModal from "modal/DeleteOrderModal";
import ProductDetailOrderWise from "./ProductDetailOrderWise";

const OrdersDetail = () => {
  const { orderData } = useUserContext();
  const navigate = useNavigate();

  const { id } = useParams();

  const [orderDetail, setOrderDetail] = useState(null);
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
  const [showOrderDetailDeleteModal, setShowOrderDetailDeleteModal] =
    useState(false);

  useEffect(() => {
    const findOrder = orderData.filterData.find((item) => item.id === id);
    if (!findOrder) navigate(URL.ORDERS.BASE)
    setOrderDetail(findOrder);
  }, [orderData, id, navigate]);


  return (
    <Layout route={URL.ORDERSDETAIL.BASE}>
      <div>
        <div className="w-[97.4%] flex flex-col items-center mt-36 md:mt-20 ml-5">
          <div className="flex justify-between w-full mb-8">
            <div className="flex gap-5">
              <ArrowBackIcon
                onClick={() => navigate(-1)}
                sx={{
                  color: "#ff2f60",
                  cursor: "pointer",
                  fontSize: "32px",
                }}
              />
              <h1 className="font-semibold text-[1.125rem] py-[2px]">
                Order ID:{" "}
                <span className="font-normal ">
                  {orderDetail && orderDetail.id}
                </span>
              </h1>
            </div>
            <h1 className="font-semibold text-[1.125rem]">
              Date:{" "}
              <span className="font-normal ">
                {dateFormatter(orderDetail && orderDetail.createdAt)}
              </span>
            </h1>
          </div>
          <div className="border-2 border-[#e6e6e6] rounded-[0.375rem] p-5 w-full mb-5 flex justify-between">
            <div>
              <h1 className="font-semibold text-[1.125rem] mb-5">
                Username:{" "}
                <span className="font-normal ">
                  {orderDetail && orderDetail.address
                    ? FirstLetterCapital(orderDetail.address.name)
                    : ""}
                </span>
              </h1>
              <h1 className="font-semibold text-[1.125rem] mb-5">
                Phone Number:{" "}
                <span className="font-normal ">
                  {orderDetail && orderDetail.user_id.phone_number},
                </span>
              </h1>
              <h1 className="font-semibold text-[1.125rem] mb-5">
                Email:{" "}
                <span className="font-normal ">
                  {(orderDetail && orderDetail?.address?.email) ?? "No Email"}
                </span>
              </h1>
              <h1 className="font-semibold text-[1.125rem] mb-5">
                Delivery Address:{" "}
                <span className="font-normal">
                  {orderDetail?.address.apartment},{" "}
                  {orderDetail && orderDetail.address.address},{" "}
                  {orderDetail && orderDetail.address.city}, (
                  <span className="font-semibold">
                    {orderDetail && orderDetail.address
                      ? FirstLetterCapital(orderDetail.address.label)
                      : ""}
                  </span>
                  )
                </span>
              </h1>
              <h1 className="font-semibold text-[1.125rem]">
                Delivery Instruction:{" "}
                <span className="font-normal ">
                  {orderDetail && orderDetail.address.delivery_instruction
                    ? orderDetail.address.delivery_instruction
                    : "-"}
                </span>
              </h1>
            </div>
            <div className="flex gap-x-2">
              <EditIcon
                onClick={() => setShowOrderDetailModal(!showOrderDetailModal)}
                sx={{ cursor: "pointer", color: "#ff2f60" }}
              />
              {showOrderDetailModal && (
                <EditOrderModal
                  orderDetail={orderDetail}
                  setShowOrderDetailModal={setShowOrderDetailModal}
                />
              )}
              <DeleteIcon
                onClick={() =>
                  setShowOrderDetailDeleteModal(!showOrderDetailDeleteModal)
                }
                sx={{ cursor: "pointer", color: "#ff2f60" }}
              />
              {showOrderDetailDeleteModal && (
                <DeleteOrderModal
                  orderDetail={orderDetail}
                  setShowOrderDetailDeleteModal={setShowOrderDetailDeleteModal}
                />
              )}
            </div>
          </div>
          {orderDetail &&
            Object.values(orderDetail?.brands).map((products, brandIndex) => {
              return (
                <ProductDetailOrderWise
                  products={products}
                  brandIndex={brandIndex}
                  orderDetail={orderDetail}
                />
              );
            })}

          {orderDetail && (
            <div className="bg-[#ff1d52] rounded-[0.375rem] p-5 flex flex-col justify-between w-full gap-y-4">
              <div className="flex justify-between w-full">
                <h1 className="font-medium text-white">Sub-Total</h1>
                <h1 className="font-semibold text-white">
                  <h1 className="font-semibold">
                    {`Rs: ${orderDetail.sub_total}`}</h1>
                </h1>
              </div>
              <div className="flex justify-between w-full">
                <h1 className="font-medium text-white">Delivery Charges</h1>
                <h1 className="font-semibold text-white">
                  <h1 className="font-semibold">
                    {orderDetail.delivery_charges > 0 ? `Rs: ${orderDetail.delivery_charges}` : "Free"}
                  </h1>
                </h1>
              </div>
              <hr />
              <div className="flex justify-between w-full">
                <h1 className="font-medium text-white">Total</h1>
                <h1 className="font-semibold text-white">
                  <h1 className="font-semibold">{`Rs: ${orderDetail.amount}`}</h1>
                </h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default OrdersDetail;
