import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { HTTP_STATUS_CODES } from "constants";
import { useUserContext } from "context";
import { toastError, toastSuccess } from "api/integration/toast";
import { updateContactsByIdEndPoint } from "api/endpoints/contact";

const ActionDropdown = ({ row }) => {
  const { token, onGetAllContacts, setLoading } = useUserContext();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  const handleMenuClick = async (e, id, status) => {
    e.preventDefault();
    await onUpdateStatus(id, status);
  };

  const onUpdateStatus = async (id, status) => {
    const payload = {
      status: status,
    };

    try {
      setLoading(true);
      const response = await updateContactsByIdEndPoint(token, payload, id);
      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        toastSuccess("Status Changed Successfully");
        onGetAllContacts();
      } else {
        toastError("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toastError("An error occurred while updating status");
    }
  };

  return (
    <div className="flex ">
      <div
        className="relative cursor-pointer p-1 font-medium border py-1 px-2 rounded-md"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <MoreHorizIcon />
        {
          <div
            className={`absolute top-3 left-0  transition-all transition-duration:500ms bg-white rounded-md shadow-lg mt-3  w-[140px] z-10 overflow-hidden  `}
            style={{ maxHeight: isDropdownOpen ? "250px" : "0px" }}
          >
            <div className="text-left pl-4 py-3 text-[#00000073]">
              Update Status
            </div>
            <div className="text-black px-2 text-[14px] pb-2 font-normal cursor-pointer text-left">
              <div
                className="text-black pl-3 text-[14px] pb-2 font-normal cursor-pointer text-left"
                onClick={(e) => handleMenuClick(e, row.id, "pending")}
              >
                <p className="hover:text-[#ff1d52]">Pending</p>
              </div>
              <div
                className="text-black pl-3 py-2 text-[14px] font-normal cursor-pointer text-left"
                onClick={(e) => handleMenuClick(e, row.id, "in-process")}
              >
                <p className="hover:text-[#ff1d52]">In-process</p>
              </div>
              <div
                className="text-black pl-3 py-2 text-[14px] font-normal cursor-pointer text-left"
                onClick={(e) => handleMenuClick(e, row.id, "completed")}
              >
                <p className="hover:text-[#ff1d52]">Completed</p>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default ActionDropdown;
