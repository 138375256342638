import React, { useEffect, useState } from "react";
import { UserContext } from "context";
import { useCookies } from "react-cookie";
import { authVerifyEndpoint } from "api/endpoints/auth";
import { HTTP_STATUS_CODES } from "constants";
import { getAllSubCategoryEndPoint } from "api/endpoints/sub-category";
import { toastError } from "api/integration/toast";
import { getAllBrandsEndPoint } from "api/endpoints/brand";
import { getAllUsersEndPoint } from "api/endpoints/user";

import _ from "lodash";
import { getAllOrdersEndPoint } from "api/endpoints/order";
import { getAllContactsEndPoint } from "api/endpoints/contact";

export const PropsProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["token", "user"]);
  const [token, setToken] = useState(cookies.token);
  const [authenticated, setAuthenticated] = useState(
    cookies.token ? true : false
  );
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [showModal, setShowModal] = useState(null);
  const [modalState, setModalState] = useState({});
  const [toggleSideBar, setSideBar] = useState(false);

  const [userData, setUserData] = useState({
    data: [],
    filterData: [],
  });

  const [subCategoryData, setSubCategoryData] = useState({
    data: [],
    filterData: [],
  });

  const [brandData, setBrandData] = useState({
    data: [],
    filterData: [],
  });
  const [orderData, setOrderData] = useState({
    data: [],
    filterData: [],
  });
  const [contactData, setContactData] = useState({
    data: [],
    filterData: [],
  });
  const [orderPage, setOrderPage] = useState(0);
  const [count, setCount] = useState(0);
  const [orderPageNumber, setOrderPageNumber] = useState(1);
  // Get all Users from API

  const onGetAllUser = async () => {
    try {
      const response = await getAllUsersEndPoint(token);

      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        setUserData({
          ...userData,
          data: response.data,
          filterData: response.data,
        });
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const onGetAllOrders = async (pageNumber = 1, rowsPerPage = 25) => {
    try {
      const response = await getAllOrdersEndPoint(
        token,
        pageNumber,
        rowsPerPage
      );
      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        const filterData = response.data.data.map((item) => {
          return {
            ...item,
            brands: _.groupBy(item.products, "product_id.modjen_brand_name"),
            products: "",
          };
        });

        setOrderData({
          ...orderData,
          data: filterData,
          filterData: filterData,
        });

        setCount(response.data.count);
        setOrderPage(response.data.totalPage);
      }
    } catch (error) {
      // Handle error
    }
  };

  // Get all sub categories from API

  const onGetAllSubCategory = async () => {
    try {
      const response = await getAllSubCategoryEndPoint(token);

      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        setSubCategoryData({
          ...subCategoryData,
          data: response.data,
          filterData: response.data,
        });
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  // Get all Brands from API

  const onGetAllBrand = async () => {
    try {
      const response = await getAllBrandsEndPoint(token);

      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        setBrandData({
          ...brandData,
          data: response.data,
          filterData: response.data,
        });
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const onGetAllContacts = async () => {
    try {
      const response = await getAllContactsEndPoint(token);

      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        setContactData({
          ...contactData,
          data: response.data,
          filterData: response.data,
        });
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  useEffect(() => {
    if (loading) {
      onLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (token) return setCookie("token", token, { path: "/" });

    if (!authenticated) {
      onLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, token, user]);

  const onLoad = async () => {
    if (token) {
      const response = await authVerifyEndpoint(token);

      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        setToken(response.data.token);
        setUser(response.data.user);
      }
    } else {
      onReset();
    }
    setLoading(false);
  };

  const onReset = () => {
    setUser();
    removeCookie("token", { path: "/" });
    setToken("");
    setAuthenticated(false);
  };

  const setModalAndState = ({ modal, state }) => {
    setModalState(state);
    setShowModal(modal);
  };

  return (
    <>
      <UserContext.Provider
        value={{
          cookies,
          authenticated,
          loading,
          token,
          user,
          subCategoryData,
          setSubCategoryData,
          brandData,
          setBrandData,
          userData,
          orderPageNumber,
          setOrderPageNumber,
          setUserData,
          orderData,
          setOrderData,
          contactData,
          setContactData,
          count,
          setCount,
          orderPage,
          setOrderPage,
          setCookie,
          setAuthenticated,
          setLoading,
          setToken,
          setUser,
          onReset,
          onGetAllUser,
          onGetAllSubCategory,
          onGetAllBrand,
          onGetAllOrders,
          onGetAllContacts,
          showModal,
          modalState,
          setModalAndState,
          toggleSideBar,
          setSideBar,
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  );
};

export default PropsProvider;
