import React, { useEffect, useMemo, useState } from "react";
import ProductDetail from "../ProductsDetail";
import { updateOrderByIdEndPoint } from "api/endpoints/order";
import { HTTP_STATUS_CODES } from "constants";
import { toastError, toastSuccess } from "api/integration/toast";
import { useUserContext } from "context";
import { STATUS } from "constants";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import pickUp from "../../../../../assets/images/pick-up.svg";
import inProcess from "../../../../../assets/images/on-process.svg";
import onDelievery from "../../../../../assets/images/on-delivery.svg";
import completed from "../../../../../assets/images/deliverd.svg";
import _ from "lodash";

const ProductDetailOrderWise = ({ products, brandIndex, orderDetail }) => {
  const [currentBrand, setCurrentBrand] = useState();
  const {
    token,
    onGetAllOrders,
    setLoading,
    orderPageNumber,
    brandData,
    onGetAllBrand,
  } = useUserContext();
  const handleMenuClick = async (e, id, product) => {
    e.preventDefault();
    const status = e.currentTarget.innerText.toLowerCase().replace(" ", "-");
    await onUpdateStatus(status, id, product);
  };

  const groupedItems = useMemo(
    () => _.groupBy(products, 'product_id.modjen_brand_name'),
    [products]
  );

  const deliveryChargesByBrand = useMemo(() => {
    const charges = {};
    const brandOffer = {};
    let deliveryCharges = 0;
    brandData?.data?.forEach((brand) => {
      if (groupedItems[brand.name]) {
        const sumProductValue = groupedItems[brand.name]
          .map(obj => obj.price * obj.quantity)
          .reduce((acc, curr) => acc + curr, 0);
        charges[brand.name] =
          brand.delivery_offer === 0
            ? brand.delivery_charges
            : sumProductValue > brand.delivery_offer
              ? 0
              : brand.delivery_charges;
        brandOffer[brand.name] = brand.delivery_offer;
        deliveryCharges += +charges[brand.name] ?? 0;
      }
    });
    return {
      charges,
      brandOffer,
      deliveryCharges
    };
  }, [brandData?.data, groupedItems]);


  useEffect(() => {
    onGetAllBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = brandData?.data?.find(
      (brand) => brand.name === products[0]?.product_id?.modjen_brand_name ?? null
    );
    setCurrentBrand(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandData]);

  const onUpdateStatus = async (status, id, product) => {
    const payload = {
      status,
    };

    try {
      setLoading(true);

      const response = await updateOrderByIdEndPoint(
        token,
        payload,
        id,
        product.id
      );

      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        toastSuccess("Status changed successfully");
        onGetAllOrders(orderPageNumber);
      } else {
        toastError("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toastError("An error occurred while updating status");
    }
  };

  return (
    <div className="w-full" key={brandIndex}>
      <div className="rounded-[0.375rem] p-5 w-full border-2 border-[#e6e6e6] mb-8">
        <h1 className="font-semibold text-[1.125rem] mb-4">
          {products[0]?.product_id?.modjen_brand_name?.toUpperCase() ?? "No Brand"} - Delivery
          Status
        </h1>
        <div className="status-bar flex justify-between relative md:px-4 md:py-2 mt-5 mb-5 w-full">
          <div
            onClick={(e) => handleMenuClick(e, orderDetail.id, products[0])}
            className="flex items-center flex-col my-2 cursor-pointer"
          >
            <div
              onClick={onGetAllOrders}
              className={`icon grid place-items-center lg:w-14 lg:h-14 p-1.5 lg:p-0 rounded-full z-[1] ${products[0].status === STATUS.PENDING
                ? "bg-[#ff2f60]"
                : "bg-[#cdcdcd]"
                } `}
            >
              <PendingActionsIcon
                sx={{
                  fontSize: "32px",
                  color: "white",
                }}
              />
            </div>
            <span className="mt-2 font-bold capitalize text-[12px] md:text-sm md:text-md text-disabled">
              pending
            </span>
          </div>
          <div
            onClick={(e) => handleMenuClick(e, orderDetail.id, products[0])}
            className="flex items-center flex-col my-2 cursor-pointer before:absolute before:w-[25%] md:before:h-1 before:h-0.5 before:top-[50%] before:-translate-y-3 before:translate-x-[-40%] before:bg-[#cdcdcd]"
          >
            <div
              onClick={onGetAllOrders}
              className={`icon grid place-items-center lg:w-14 lg:h-14 p-1.5 lg:p-0 rounded-full z-[1] ${products[0].status === STATUS.PICK_UP
                ? "bg-[#ff2f60]"
                : "bg-[#cdcdcd]"
                } `}
            >
              <img
                alt="pick up"
                loading="lazy"
                width="32"
                height="32"
                decoding="async"
                data-nimg="1"
                src={pickUp}
                style={{ color: "transparent" }}
              />
            </div>
            <span className="mt-2 font-bold capitalize text-[12px] md:text-sm md:text-md text-disabled">
              pick up
            </span>
          </div>
          <div
            onClick={(e) => handleMenuClick(e, orderDetail.id, products[0])}
            className="flex items-center flex-col my-2 cursor-pointer before:absolute lg:before:w-2/6 before:w-[29%] md:before:h-1 before:h-0.5 before:top-[50%] before:-translate-y-3 before:translate-x-[-40%] before:bg-[#cdcdcd]"
          >
            <div
              onClick={onGetAllOrders}
              className={`icon grid place-items-center lg:w-14 lg:h-14 p-1.5 lg:p-0 rounded-full z-[1] ${products[0].status === STATUS.IN_PROCESS
                ? "bg-[#ff2f60]"
                : "bg-[#cdcdcd]"
                } `}
            >
              <img
                alt="in process"
                loading="lazy"
                width="32"
                height="32"
                decoding="async"
                data-nimg="1"
                src={inProcess}
                style={{ color: "transparent" }}
              />
            </div>
            <span className="mt-2 font-bold capitalize text-[12px] md:text-sm md:text-md text-disabled">
              in process
            </span>
          </div>
          <div
            onClick={(e) => handleMenuClick(e, orderDetail.id, products[0])}
            className="flex items-center flex-col cursor-pointer my-2 before:absolute lg:before:w-2/6 before:w-[29%] md:before:h-1 before:h-0.5 before:top-[50%] before:-translate-y-3 before:translate-x-[-40%] before:bg-[#cdcdcd]"
          >
            <div
              onClick={onGetAllOrders}
              className={`icon grid place-items-center lg:w-14 lg:h-14 p-1.5 lg:p-0 rounded-full z-[1] ${products[0].status === STATUS.ON_DELIEVERY
                ? "bg-[#ff2f60]"
                : "bg-[#cdcdcd]"
                }`}
            >
              <img
                alt="on delivery"
                loading="lazy"
                width="32"
                height="32"
                decoding="async"
                data-nimg="1"
                src={onDelievery}
                style={{ color: "transparent" }}
              />
            </div>
            <span className="mt-2 font-bold capitalize text-[12px] md:text-sm md:text-md text-disabled">
              on delivery
            </span>
          </div>
          <div
            onClick={(e) => handleMenuClick(e, orderDetail.id, products[0])}
            className="flex items-center flex-col cursor-pointer my-2 before:absolute before:w-[22%] md:before:h-1 before:h-0.5 before:top-[50%] before:-translate-y-3 before:translate-x-[-44%] before:bg-[#cdcdcd]"
          >
            <div
              onClick={onGetAllOrders}
              className={`icon grid place-items-center lg:w-14 lg:h-14 p-1.5 lg:p-0 rounded-full z-[1] ${products[0].status === STATUS.COMPLETED
                ? "bg-[#ff2f60]"
                : "bg-[#cdcdcd]"
                } `}
            >
              <img
                alt="completed"
                loading="lazy"
                width="32"
                height="32"
                decoding="async"
                data-img="1"
                src={completed}
                style={{ color: "transparent" }}
              />
            </div>
            <span className="mt-2 font-bold capitalize text-[12px] md:text-sm md:text-md text-disabled">
              completed
            </span>
          </div>
        </div>{" "}
        {products?.map((product, productIndex) => (
          <ProductDetail product={product} index={productIndex} />
        ))}
        <div className="capitalize flex flex-col justify-between mt-3 font-semibold rounded-md text-black bg-gray-300 py-3 px-2 mb-3">
          <div className="w-full flex justify-between  ">
            <span>{currentBrand?.name ?? "No Brand"} - (Delivery Charges) </span>{' '}
            <span>
              {!!deliveryChargesByBrand.charges[currentBrand?.name]
                ?
                `Rs: ${deliveryChargesByBrand.charges[currentBrand?.name]}`

                : 'Free'}
            </span>
          </div>
          {!!deliveryChargesByBrand.charges[currentBrand?.name] &&
            !!deliveryChargesByBrand.brandOffer[currentBrand?.name] && (
              <span className="text-[10px] text-gray-600 ">
                Get free delivery by shopping over{' '}
                {
                  deliveryChargesByBrand.brandOffer[currentBrand?.name]
                }{' '}
                from {currentBrand?.name}
              </span>
            )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetailOrderWise;
