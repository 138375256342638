import React from "react";
import ProductTable from "./components/ProductTable";
import Layout from "layout";
import { URL } from "constants";

const Product = () => {

  return (
    <Layout route={URL.PRODUCTS.BASE}>
      <ProductTable />
    </Layout>
  );
};

export default Product;
