import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Layout from "layout";
import Searchbar from "components/Searchbar";
import BrandTable from "./components/BrandTable";
import AddBrandModal from "./components/AddBrandModal";
import { URL } from "constants";
import { useUserContext } from "context";

const Brands = () => {
  const { brandData, setBrandData, onGetAllBrand, setLoading } = useUserContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    onGetAllBrand();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (value) => {
    if (value === "") {
      setBrandData({ ...brandData, filterData: brandData.data });
    } else {
      const updatedList = brandData.data.filter((x) => {
        const name = x.name ? x.name.toLowerCase() : "";
        const id = x.id ? x.id.toLowerCase() : "";
        const status = x.status ? x.status.toLowerCase() : "";

        return (
          name.includes(value.toLowerCase()) ||
          id.includes(value.toLowerCase()) ||
          status.includes(value.toLowerCase())
        );
      });
      setBrandData({ ...brandData, filterData: updatedList });
    }
  };

  const handleBrandModalClose = () => {
    setIsModalOpen(false);
  };

  const handleBrandModalOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <Layout route={URL.BRANDS.BASE}>
      <Searchbar onSearchChange={handleSearchChange} />
      <div className="flex justify-end my-3">
        <Button
          onClick={handleBrandModalOpen}
          sx={{
            background: "#FF2F60",
            color: "white",
            display: "flex",
            alignItems: "center",
            gap: "5px",

            boxShadow: 1,
            "&:hover": {
              background: "#FF005C",
              boxShadow: 3,
            },
          }}
        >
          <AddIcon sx={{ fontSize: "20px" }} />
          Create new Brand
        </Button>
      </div>
      <BrandTable filterData={brandData.filterData} />
      <AddBrandModal
        open={isModalOpen}
        onClose={handleBrandModalClose}
        isEditing={false}
      />
    </Layout>
  );
};

export default Brands;
