import React from "react";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import { URL } from "../constants";

const Layout = ({ children, route, showTopBar = true }) => {



  return (
    <div className="flex relative">
      <Sidebar
        route={route}
      />
      <div
        className={`w-full flex flex-col p-[30px] relative overflow-auto bg-[#F4F7FE] h-screen ${route !== URL.SETTING.BASE &&
          route !== URL.WRONG.BASE &&
          route !== URL.FINAL.BASE
          ? ""
          : `px-[20px] p-[5px]`
          } `}
      >
        <Topbar route={route} showTopBar={showTopBar} />
        {children}
      </div>
    </div>
  );
};

export default Layout;
