import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import noData from "../../../../assets/images/no-data.png";
import OrderModal from "../OrderModal";
import OrderTableRow from "./components/OrderTableRow";
import { ORDER_COLUMNS } from "./data";
import { useUserContext } from "context";
import Loader from "components/Loader";

export default function OrderTable({ filterData, count }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { orderPage, onGetAllOrders, setOrderPageNumber } = useUserContext();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    setSelectedRow(null);
    setIsModalOpen(false);
  };

  const handleOpenModal = (row, column) => {
    if (column === "action") {
      return;
    }
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPageNumber = Math.floor((page * rowsPerPage) / newRowsPerPage) + 1;

    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setOrderPageNumber(newPageNumber);

    onGetAllOrders(newPageNumber, newRowsPerPage);
  };

  const handleChangePage = async (event, newPage) => {
    if (newPage >= 0 && newPage < orderPage) {
      setPage(newPage);
      const newPageNumber = newPage + 1;
      setOrderPageNumber(newPageNumber);
      onGetAllOrders(newPageNumber, rowsPerPage);
    }
    setIsButtonDisabled(true);
    setIsLoading(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
      setIsLoading(false);
    }, 1000);
  };

  return (
    <>
      <div
        style={{ marginBottom: "15px", marginTop: "8px", fontWeight: "bold" }}
      >
        Total Orders: {count}
      </div>
      <Paper sx={{ width: "100%", boxShadow: "none" }}>
        <TableContainer
          sx={{
            "&::-webkit-scrollbar": {
              width: 4,
              height: 4,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "white",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#FF1D52",
              borderRadius: 2,
            },
            height: "auto"
            // minHeight: "63vh", overflowY: "auto",
          }}
        >
          {filterData.length ? (
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ fontWeight: "bold", fontSize: "18px" }}>
                <TableRow>
                  {ORDER_COLUMNS.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      sx={{ fontWeight: "bold", fontSize: 15 }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filterData.map((row, i) => (
                  <React.Fragment key={row.id}>
                    <OrderTableRow
                      key={row.id}
                      row={row}
                      handleOpenModal={handleOpenModal}
                    />
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="flex item-center justify-center items-center h-full flex-col">
              <img src={noData} className="h-16 w-16" alt="" />
              <span>No data available.</span>
            </div>
          )}
        </TableContainer>
        <TablePagination
          sx={{
            color: "black",

            "& .MuiTablePagination-actions": {
              color: "#ff2f60",
            },
          }}
          SelectProps={{
            disabled: isButtonDisabled,
          }}
          backIconButtonProps={
            isButtonDisabled
              ? {
                disabled: isButtonDisabled,
              }
              : undefined
          }
          nextIconButtonProps={
            isButtonDisabled
              ? {
                disabled: isButtonDisabled,
              }
              : undefined
          }
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {selectedRow && (
        <OrderModal
          open={isModalOpen}
          onClose={handleCloseModal}
          rowData={selectedRow}
        />
      )}
      {isLoading && <Loader />}
    </>
  );
}
