import React, { useState } from "react";
import Layout from "layout";
import AnalyticsScreen from "./components/AnalyticsScreen";
import { URL } from "constants";
import { useEffect } from "react";
import { getAllAnalyticsEndPoint, getAllAnalyticsReportEndPoint } from "api/endpoints/analytics";
import { useUserContext } from "context";
import { HTTP_STATUS_CODES } from "constants/index.js";
import BrandAndCityReport from "./components/BrandAndCityReport";
const Dashboard = () => {
  const { token, setLoading } = useUserContext();
  const [analytics, setAnalytics] = useState();
  const [analyticsReport, setAnalyticsReport] = useState({ brands: [], city: [] });

  const fetchData = async () => {
    const response = await getAllAnalyticsEndPoint(token);
    if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
      setAnalytics(response.data);
    }
  };
  const fetchReportData = async () => {
    const response = await getAllAnalyticsReportEndPoint(token);
    if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
      setAnalyticsReport(response.data);
    }
  };

  useEffect(() => {
    fetchData();
    fetchReportData();
    setLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <div>
      <Layout route={URL.DASHBOARD.BASE}>
        <AnalyticsScreen
          data={analytics}
        />
        <BrandAndCityReport data={analyticsReport} />
      </Layout>
    </div>
  );
};

export default Dashboard;
