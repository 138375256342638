// export const BASE_SERVER = "http://192.168.18.21:3001";
export const BASE_SERVER = process.env.REACT_APP_BACKEND_URL;
export const SERVER = BASE_SERVER + "/api";

export const URL = {
  HOME: {
    ROUTE: "home",
    BASE: "/",
  },
  LOGIN: {
    ROUTE: "login",
    BASE: "/login",
  },
  DASHBOARD: {
    ROUTE: "dashboard",
    BASE: "/dashboard",
  },
  USERS: {
    ROUTE: "user",
    BASE: "/dashboard/user",
  },
  ORDERS: {
    ROUTE: "order",
    BASE: "/dashboard/order",
  },
  PRODUCTS: {
    ROUTE: "product",
    BASE: "/dashboard/product",
  },
  BRANDS: {
    ROUTE: "brand",
    BASE: "/dashboard/brand",
  },
  SETTING: {
    ROUTE: "setting",
    BASE: "/dashboard/setting",
  },
  BANNERS: {
    ROUTE: "banner",
    BASE: "/dashboard/banner",
  },
  SUBCATEGORY: {
    ROUTE: "subcategory",
    BASE: "/dashboard/subcategory",
  },
  WRONG: {
    ROUTE: "wrong",
    BASE: "/dashboard/wrong",
  },
  FINAL: {
    ROUTE: "final",
    BASE: "/dashboard/final",
  },
  CONTACT_US: {
    ROUTE: "contact-us",
    BASE: "/dashboard/contact-us",
  },
  ORDERSDETAIL: {
    ROUTE: "ordersdetail",
    BASE: "/dashboard/orders-detail/:id",
  },
  NOT_FOUND_PAGE: "*",
};

export const REQ = {
  USER: {
    SIGN_IN: SERVER + "/auth/signin",
    VERIFY: SERVER + "/auth/verify-user",
    GET_ALL: SERVER + "/user",
    UPDATE_BY_ID: SERVER + "/user/:id",
    DELETE_BY_ID: SERVER + "/user/:id",
  },
  BRAND: {
    GET_ALL: SERVER + "/brand",
    POST: SERVER + "/brand",
    UPDATE_BY_ID: SERVER + "/brand/:id",
    DELETE_BY_ID: SERVER + "/brand/:id",
  },
  ANALYTICS: {
    GET_ALL: SERVER + "/analytics",
    BRANDS_AND_CITIES: SERVER + "/analytics/sale-report",
  },
  SUB_CATEGORY: {
    POST: SERVER + "/sub-category",
    GET_ALL: SERVER + "/sub-category",
    DELETE_BY_ID: SERVER + "/sub-category/:id",
    UPDATE_BY_ID: SERVER + "/sub-category/:id",
  },
  PRODUCT: {
    GET_ALL: SERVER + "/product/paginated",
    GET_ALL_MANUAL_CHECK: SERVER + "/product/manual-check",
    UPDATE_PRODUCT_BY_ID: SERVER + "/product/:id",
    DELETE_BY_ID: SERVER + "/product/:id",
  },
  ORDER: {
    // GET_ALL: SERVER + "/order/get-all",
    GET_ALL: SERVER + "/order/paginated",
    UPDATE_ORDER_BY_ID: SERVER + "/order/:id",
    UPDATE_ORDER_STATUS_BY_ID: SERVER + "/order/status/:id/:productId",
    UPDATE_MAIN_ORDER_STATUS_BY_ID: SERVER + "/order/main-status/:id",
  },
  CONTACT_US: {
    GET_ALL: SERVER + "/contact",
    UPDATE_CONTACT_BY_ID: SERVER + "/contact/:id",
    UPDATE_CONTACT_STATUS_BY_ID: SERVER + "/contact/:id/:status",
  },
};

export const HTTP_STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  RESOURCE_NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

export const MANUAL_CHECK = {
  NOT_CHECKED: "not_checked",
  WRONG: "wrong",
  FINAL: "final",
};

export const SIDE_NAVIGATION = {
  DASHBOARD: "Dashboard",
  USERS: "Users",
  ORDERS: "Orders",
  BRANDS: "Brands",
  PRODUCTS: "Products",
  SUB_CATEGORY: "Sub Category",
  PRODUCTS_SETTING: "Products Setting",
  WRONG_PRODUCTS: "Wrong Products",
  FINAL_PRODUCTS: "Final Products",
  CONTACT_US: "Contact us",
};

export const STATUS = {
  PENDING: "pending",
  PICK_UP: "pick-up",
  IN_PROCESS: "in-process",
  ON_DELIEVERY: "on-delivery",
  COMPLETED: "completed",
};

export const MODAL_STATUS = {
  null: null,
  CHANGE_ORDER_STATUS: "change-order-status",
}
