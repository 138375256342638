import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import img from "../../../../assets/images/aghanoor.png";
import { Typography } from "@mui/material";
import "../../../../styles/css/index.scss";
import { dateFormatter } from "utils/numberFormatter/date";

const OrderModal = ({ rowData, onClose, open }) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{

        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "520px",
            borderRadius: "15px",
          },
          "&::-webkit-scrollbar": {
            width: 4,
            height: 4,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "white",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#FF1D52",
            borderRadius: 2,
          },
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          background: "#ff1d52",
          color: "white",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer"

        }}

      >
        {"Order Detail"}
        <DialogActions>
          <CloseIcon onClick={onClose} sx={{ fontSize: "18px" }} />
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography sx={{ marginTop: "10px", fontWeight: "bold" }}>
            Product Detail
          </Typography>
          <div
            className="productDetail max-h-[300px] overflow-y-auto px-3 my-5"
            style={{ scrollbarWidth: "thin", scrollbarColor: "red yellow" }}
          >
            {Object.entries(rowData.brands).map(([brandName, products]) => (
              <React.Fragment key={brandName}>
                {products.map((item, index) => (
                  <div
                    className="w-full h-auto flex justify-start items-center bg-white py-4 border-b border-gray-100 relative last:border-b-0 z-0"
                    key={index}
                  >
                    <div className="relative flex rounded-md overflow-hidden bg-gray-200 flex-shrink-0 cursor-pointer me-4">
                      <div className="inline-block max-w-[100%] overflow-hidden  relative m-0">
                        <div className="max-w-[100%] block">
                          <img
                            src={img}
                            alt={item.title}
                            className="max-w-[100px] h-[120px] bg-cover bg-center"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col w-full overflow-hidden relative">
                      <div className="brand_name font-semibold text-sm text-secondary uppercase">
                        {brandName}
                      </div>
                      <h1 className="truncate text-sm text-secondary">
                        <span className="text-black text-lg truncate font-semibold my-1 capitalize">
                          {item.title}
                        </span>
                      </h1>
                      <div className="variant my-1 ml-[2px]">{item.variant}</div>
                      <div className="flex items-end justify-between">
                        <div className="group flex items-center justify-between rounded-md overflow-hidden flex-shrink-0 mt-1 h-8 md:h-9 shadow-navigation bg-heading">
                          <span className="font-semibold text-secondary flex items-center justify-center h-full transition-colors duration-250 ease-in-out cursor-default flex-shrink-0 text-sm">
                            {`Qty: ${item.quantity}`}
                          </span>
                        </div>
                        <span className="absolute bottom-0 right-0 font-bold text-secondary">
                          {`Rs. ${item.price}`}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>

          <div className="flex items-end flex-col my-2">
            <div className="group flex items-center justify-between rounded-md overflow-hidden flex-shrink-0 mt-1 h-8 md:h-9 shadow-navigation bg-heading">
              <span>Total Quantity: </span>
              <span className="font-semibold text-secondary flex items-center justify-center h-full transition-colors duration-250 ease-in-out cursor-default flex-shrink-0 text-sm">
                {rowData.total_quantity}
              </span>
            </div>
            <div className="flex">
              <span>Total SubTotal: </span>
              <span className="font-semibold">{`Rs. ${rowData.sub_total}`}</span>
            </div>
          </div>

          <div className="flex justify-between py-2 border-b text-sm md:text-base">
            <span className="font-bold">ID:</span>
            <span>{rowData.id.substring(18)}</span>
          </div>

          <div className="flex justify-between py-2 border-b text-sm md:text-base">
            <span className="font-bold">Name:</span>
            <span>{rowData.user_id.username}</span>
          </div>

          <div className="flex justify-between py-2 border-b selection:text-sm md:text-base">
            <span className="font-bold">Phone No:</span>
            <span>{rowData.user_id.phone_number}</span>
          </div>

          <div className="flex justify-between py-2 text-sm md:text-base border-b">
            <span className="font-bold">City:</span>
            <span>{rowData.address.city}</span>
          </div>

          {Object.values(rowData.brands).map((products, brandIndex) => (
            <React.Fragment key={brandIndex}>

            </React.Fragment>
          ))}


          <div className="flex justify-between py-2 text-sm md:text-base border-b">
            <span className="font-bold">Amount:</span>
            <span>{rowData.amount}</span>
          </div>

          <div className="flex justify-between py-2 text-sm md:text-base">
            <span className="font-bold">Date:</span>
            <span>{dateFormatter(rowData.createdAt)}</span>
          </div>


        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default OrderModal;
