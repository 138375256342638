import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import noData from "../../../../assets/images/no-data.png";
import SubCategoryTableRow from "./components/SubCategoryTableRow";
import { SUB_CATEGORY_COLUMNS } from "./data";
import Loader from "components/Loader";

const SubCategoryTable = ({ filterData, getAllSubCategory }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsButtonDisabled(true);
    setIsLoading(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
      setIsLoading(false);
    }, 2000);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div
        style={{ marginBottom: "15px", marginTop: "8px", fontWeight: "bold" }}
      >
        Total Sub Category: {filterData.length}
      </div>
      <Paper sx={{ width: "100%", boxShadow: "none" }}>
        <TableContainer
          sx={{
            "&::-webkit-scrollbar": {
              width: 4,
              height: 4,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "white",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#FF1D52",
              borderRadius: 2,
            },
            height: "55vh",
          }}
        >
          {filterData.length ? (
            <Table stickyHeader aria-label="sticky table">
              <TableHead
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                style={{ zIndex: 1, position: "relative" }}
              >
                <TableRow>
                  {SUB_CATEGORY_COLUMNS.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      sx={{ fontWeight: "bold", fontSize: 15 }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filterData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <SubCategoryTableRow key={row.id} row={row} />
                  ))}
              </TableBody>
            </Table>
          ) : (
            <div className="flex item-center justify-center items-center h-full flex-col">
              <img src={noData} className="h-16 w-16" alt=""/>
              <span>No data available.</span>
            </div>
          )}
        </TableContainer>

        <TablePagination
          sx={{
            color: "black",

            "& .MuiTablePagination-actions": {
              color: "#ff2f60",
            },
          }}
          SelectProps={{
            disabled: isButtonDisabled,
          }}
          backIconButtonProps={
            isButtonDisabled
              ? {
                  disabled: isButtonDisabled,
                }
              : undefined
          }
          nextIconButtonProps={
            isButtonDisabled
              ? {
                  disabled: isButtonDisabled,
                }
              : undefined
          }
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filterData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {isLoading && <Loader />}
    </>
  );
};
export default SubCategoryTable;
