export const numberFormatter = (number, digits) => {
  let units = [
    {
      value: 1,
      symbol: "",
    },
    {
      value: 1e3,
      symbol: "K",
    },
    {
      value: 1e6,
      symbol: "M",
    },
    {
      value: 1e9,
      symbol: "B",
    },
    {
      value: 1e12,
      symbol: "T",
    },
    {
      value: 1e15,
      symbol: "q",
    },
    {
      value: 1e18,
      symbol: "Q",
    },
  ];

  const expression = /\.0+$|(\.[0-9]*[1-9])0+$/;

  let item = units
    .slice()
    .reverse()
    .find((item) => number >= item.value);

  return item
    ? (number / item.value)
        .toFixed(digits ? digits : 2)
        .replace(expression, "$1") + item.symbol
    : "0";
};
    