import { callDelete, callGet, callPatch } from "api/integration/call";
import { REQ } from "constants/index";

// export const getAllOrdersEndPoint = async (token) => {
//   return await callGet(REQ.ORDER.GET_ALL, token);
// };

export const getAllOrdersEndPoint = async (token, pageNumber, rowsPerPage) => {
  return callGet(
    `${REQ.ORDER.GET_ALL}?page=${pageNumber}&limit=${rowsPerPage}`,
    token
  );
};

export const deleteOrderByIdEndPoint = async (token, id) => {
  return await callDelete(
    REQ.ORDER.UPDATE_ORDER_BY_ID.replace(":id", id),
    {},
    token
  );
};
export const changeOrderStatusByIdEndPoint = async (token, id, data) => {
  return await callPatch(
    REQ.ORDER.UPDATE_MAIN_ORDER_STATUS_BY_ID.replace(":id", id),
    data,
    token
  );
};

export const updateOrderByIds = async (token, data, id) => {
  if (data.brands) {
    delete data.brands
  }

  return await callPatch(
    REQ.ORDER.UPDATE_ORDER_BY_ID.replace(":id", id),
    data,
    token
  );
};

export const updateOrderByIdEndPoint = async (token, data, id, productId) => {
  return await callPatch(
    REQ.ORDER.UPDATE_ORDER_STATUS_BY_ID.replace(":id", id).replace(
      ":productId",
      productId
    ),
    data,
    token
  );
};
