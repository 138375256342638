import React from "react";

const ProductCategory = ({ eastern, stitched, productId, onUpdateData }) => {
  return (
    <>
      <div className="min-w-fit w-60 h-[100px] rounded-2xl bg-[#ffffff] shadow-xl p-3 gap-x-8 mb-3 2xl:mb-5">
        <div className="">
          <h3 className="text-base md:text-lg text-heading font-semibold mb-2.5 capitalize">
            Product Category
          </h3>
          <div className="colors flex flex-wrap gap-3 -me-3">
            <button
              className={`cursor-pointer rounded border border-gray-100  px-3 py-1.5 relative font-regular text-[12px] transition duration-200 ease-in-out hover:border-[#ff1d52] min-h-[35px] min-w-[35px] shadow-lg ${
                eastern ? `bg-[#ff1d52] text-white font-semibold` : ""
              }`}
              onClick={() => onUpdateData(productId, "eastern", true)}
            >
              Eastern
            </button>

            <button
              className={`cursor-pointer rounded border border-gray-100  px-3 py-1.5 relative font-regular text-[12px] transition duration-200 ease-in-out hover:border-[#ff1d52] min-h-[35px] min-w-[35px] shadow-lg ${
                !eastern ? `bg-[#ff1d52] text-white font-semibold` : ""
              }`}
              onClick={() => {
                onUpdateData(productId, "eastern", false);
              }}
            >
              Western
            </button>
          </div>
          {/* <div className="border-t border-gray-300 mt-4 mb-0"></div> */}
        </div>
      </div>
      <div className="h-[100px] min-w-fit w-60 mb-3 2xl:mb-5 rounded-2xl bg-[#ffffff] shadow-xl p-3 gap-x-8">
        <h3 className="text-base md:text-lg text-heading font-semibold mb-2.5 capitalize">
          Product Type
        </h3>
        <div className="colors flex flex-wrap gap-3 -me-3">
          {eastern ? (
            <>
              <button
                className={`cursor-pointer rounded border border-gray-100  px-3 py-1.5 relative font-regular text-[12px] transition duration-200 ease-in-out hover:border-[#ff1d52] min-h-[35px] min-w-[35px] shadow-lg ${
                  stitched ? `bg-[#ff1d52] text-white font-semibold` : ""
                }`} 
                onClick={() => onUpdateData(productId, "stitched", true)}
              >
                Stitched
              </button>
              <button
                className={`cursor-pointer rounded border border-gray-100  px-3 py-1.5 relative font-regular text-[12px] transition duration-200 ease-in-out hover:border-[#ff1d52] min-h-[35px] min-w-[35px] ${
                  !stitched ? `bg-[#ff1d52] text-white font-semibold` : ""
                }`}
                onClick={() => onUpdateData(productId, "stitched", false)}
              >
                UnStitched
              </button>
            </>
          ) : (
            <button
              className={`cursor-pointer rounded border border-gray-100  px-3 py-1.5 relative font-regular text-[12px] transition duration-200 ease-in-out hover:border-[#ff1d52] min-h-[35px] min-w-[35px] ${
                stitched ? `bg-[#ff1d52] text-white font-semibold` : ""
              }`}
              onClick={() => onUpdateData(productId, "stitched", true)}
            >
              Stitched
            </button>
          )}
        </div>
        {/* <div className="border-t border-gray-300 mt-4 mb-0"></div> */}
      </div>
    </>
  );
};

export default ProductCategory;
