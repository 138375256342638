import { callDelete, callGet, callPatch, callPost } from "api/integration/call";
import { REQ } from "constants";

export const getAllSubCategoryEndPoint = async (token) => {
  return await callGet(REQ.SUB_CATEGORY.GET_ALL, token);
};

export const postSubCategoryEndPoint = async (token, data) => {
  return await callPost(REQ.SUB_CATEGORY.POST, data, token);
};

export const deleteSubCategoryEndPoint = async (token, id) => {
  return await callDelete(
    REQ.SUB_CATEGORY.DELETE_BY_ID.replace(":id", id),
    {},
    token
  );
};

export const updateSubCategoryEndPoint = async (token, data, id) => {
  return await callPatch(
    REQ.SUB_CATEGORY.UPDATE_BY_ID.replace(":id", id),
    data,
    token
  );
};
