import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddSubCategoryModal from "../AddSubCategoryModal";
import IconButton from "components/IconButton";

const ActionDropdown = ({
  openModal,
  setOpenModal,
  categoryName,
  setCategoryName,
  isEditing,
  setIsEditing,
  onUpdateSubCategory,
  onDeleteSubCategory,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  const onMenuItemClick = () => {};

  const onEditRow = () => {
    setOpenModal(true);
    setIsEditing(true);
  };

  return (
    <div className="flex ">
      <div
        className="relative cursor-pointer p-1 font-medium border py-1 px-2 rounded-md "
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <MoreHorizIcon />
        {
          <div
            className={`absolute top-3 left-0  transition-all transition-duration:500ms bg-white rounded-md shadow-lg mt-3  w-[140px] z-10 overflow-hidden  `}
            style={{ maxHeight: isDropdownOpen ? "250px" : "0px" }}
          >
            <div
              className="text-black px-5 pt-2 text-[14px] font-normal cursor-pointer text-left"
              onClick={onEditRow}
            >
              <IconButton
                text="edit"
                icon={<EditIcon sx={{ fontSize: "16px" }} />}
                className={"hover:text-[#ff1d52]"}
              />
            </div>
            <div
              className="text-black px-5 pb-2 text-[14px] font-normal cursor-pointer text-left pt-2"
              onClick={onMenuItemClick}
            >
              <IconButton
                text="delete"
                icon={<DeleteIcon sx={{ fontSize: "16px" }} />}
                className={"text-red-600"}
                onClick={onDeleteSubCategory}
              />
            </div>

            <AddSubCategoryModal
              open={openModal}
              onClose={() => setOpenModal(false)}
              isEditing={isEditing}
              categoryName={categoryName}
              setCategoryName={setCategoryName}
              onUpdateSubCategory={onUpdateSubCategory}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default ActionDropdown;
