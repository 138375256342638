import { URL } from "constants";
import Layout from "layout";
import React, { useEffect } from "react";
import ContactTable from "./components/ContactTable";
import Searchbar from "components/Searchbar";
import { useUserContext } from "context";

const ContactUs = () => {
  const { contactData, setContactData, onGetAllContacts, setLoading } =
    useUserContext();

  useEffect(() => {
    onGetAllContacts();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (value) => {
    if (value === "") {
      setContactData({ ...contactData, filterData: contactData.data });
    } else {
      const updatedList = contactData.data.filter((x) => {
        const username = x.user_id.username
          ? x.user_id.username.toLowerCase()
          : "";
        const id = x.id ? x.id.toLowerCase() : "";

        return (
          username.includes(value.toLowerCase()) ||
          id.includes(value.toLowerCase())
        );
      });
      setContactData({ ...contactData, filterData: updatedList });
    }
  };

  return (
    <Layout route={URL.CONTACT_US.BASE}>
      <Searchbar onSearchChange={handleSearchChange} />
      <ContactTable
        filterData={contactData.filterData}
        // page={page}
        // rowsPerPage={rowsPerPage}
      />
    </Layout>
  );
};

export default ContactUs;
