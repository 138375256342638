import React, { useState } from "react";
import { MDBDataTable } from "mdbreact";

const BrandAndCityReport = ({ data }) => {
  const [active, setActive] = useState("brands");

  const tableData = {
    columns: [
      {
        label: "Name",
        field: "name",
      },
      {
        label: "Total Sale",
        field: "totalSale",
      },
      {
        label: "total Products",
        field: "totalProducts",
      },
      {
        label: active === "brands" ? "City Orders" : "Brand Orders",
        field: "count",
      },
    ],
    rows: data?.[active]?.map((item) => ({
      ...item,
      count:
        active === "brands"
          ? Object.entries(item?.cityList).map(
              ([key, value], i) =>
                `${key} : ${value} ${
                  Object.entries(item?.cityList).length - 1 !== i ? "," : ""
                } `
            )
          : Object.entries(item?.brandList).map(
              ([key, value], i) =>
                `${key} : ${value} ${
                  Object.entries(item?.brandList).length - 1 !== i ? "," : ""
                } `
            ),
    })),
  };

  return (
    <div>
      <div className="flex gap-2">
        <button
          className={`btn p-2  rounded-md border border-[#ff2f60] ${
            active === "brands"
              ? "bg-[#ff2f60] text-white"
              : "bg-white text-[#ff2f60] hover:bg-[#ff2f60] hover:text-white"
          }`}
          onClick={() => setActive("brands")}
        >
          Brand Report
        </button>
        <button
          className={`btn p-2  rounded-md border border-[#ff2f60] ${
            active === "city"
              ? "bg-[#ff2f60] text-white"
              : "bg-white text-[#ff2f60] hover:bg-[#ff2f60] hover:text-white"
          }`}
          onClick={() => setActive("city")}
        >
          City Report
        </button>
      </div>
      <div className="content-table">
        <MDBDataTable
          style={{ backgroundColor: "#fff" }}
          bordered
          small
          data={tableData}
          autoWidth
          noBottomColumns
        />
      </div>
    </div>
  );
};

export default BrandAndCityReport;
