import { URL } from "constants";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserContext } from "context";

const PrivateRoute = () => {
  const { authenticated } = useUserContext();

  return authenticated ? <Outlet /> : <Navigate to={URL.HOME.BASE} />;
};

export default PrivateRoute;
