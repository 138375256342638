import userImg from "../../../../assets/images/user.png";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

const ContactModal = ({ rowData, onClose, open }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "450px",
            borderRadius: "15px",
          },
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          background: "#ff1d52",
          color: "white",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {"Contact Detail"}
        <DialogActions>
          <CloseIcon onClick={onClose} sx={{ fontSize: "18px" }} />
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="flex flex-col items-center justify-center mb-4 ">
            <img
              src={userImg}
              alt={rowData.user_id.username}
              className="h-28 w-28 md:h-32 md:w-32 p-1 rounded-full border-[1px] border-[#ff1d52] mt-6 mb-2 "
            />
            <span className="text-[#ff1d52] border-b">
              {rowData.user_id.username}
            </span>
          </div>
          <div className="flex justify-between py-2 border-b text-sm md:text-base">
            <span className="font-bold">ID:</span>
            <span>{rowData.id.substring(18)}</span>
          </div>
          <div className="flex justify-between py-2 border-b text-sm md:text-base">
            <span className="font-bold">Phone</span>
            <span>{rowData.user_id.phone_number}</span>
          </div>
          <div className="flex justify-between py-2 border-b text-sm md:text-base gap-20">
            <span className="font-bold">Subject:</span>
            <span>{rowData.subject}</span>
          </div>
          <div className="flex justify-between py-2 text-sm md:text-base gap-20">
            <span className="font-bold">Message:</span>
            <span>{rowData.message}</span>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ContactModal;
