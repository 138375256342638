import Layout from "layout";
import React from "react";
import Searchbar from "components/Searchbar";
import OrderTable from "./components/OrderTable";
import { URL } from "constants";
import { useEffect } from "react";
import { useUserContext } from "context";
import ChangeOrderStatus from "modal/ChnageOrderStatus";
import { MODAL_STATUS } from "constants";

const Orders = () => {
  const {
    orderData,
    setOrderData,
    onGetAllOrders,
    count,
    setCount,
    setLoading,
    showModal
  } = useUserContext();
  useEffect(() => {
    onGetAllOrders();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (value) => {
    if (value === "") {
      setOrderData((prevData) => ({
        ...prevData,
        filterData: [...prevData.data],
      }));
    } else {
      const updatedList = orderData.data.filter((x) => {
        const name = x.user_id.username ? x.user_id.username.toLowerCase() : "";
        const id = x.id ? x.id.toLowerCase() : "";
        const hasMatchingStatus = Object.values(x.brands)
          .flatMap((products) => Object.values(products))
          .some((product) =>
            product.status
              ? product.status.toLowerCase().includes(value.toLowerCase())
              : false
          );

        return (
          name.includes(value.toLowerCase()) ||
          id.includes(value.toLowerCase()) ||
          hasMatchingStatus
        );
      });
      setOrderData((prevData) => ({
        ...prevData,
        filterData: updatedList,
      }));
    }
  };

  return (
    <Layout route={URL.ORDERS.BASE}>
      <Searchbar onSearchChange={handleSearchChange} />
      <OrderTable
        filterData={orderData.filterData}
        count={count}
        setCount={setCount}
      />
      {showModal === MODAL_STATUS.CHANGE_ORDER_STATUS && <ChangeOrderStatus />}
    </Layout>
  );
};

export default Orders;
