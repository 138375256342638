import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import MainRouter from "./routes";
import "./styles/scss/index.scss";
import "styles/css/index.scss";
import "react-toastify/dist/ReactToastify.min.css";
import PropsProvider from "context/userContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <PropsProvider>
    <BrowserRouter>
      <MainRouter />
    </BrowserRouter>
  </PropsProvider>
);
