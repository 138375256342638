import React from "react";
import EditProduct from "./EditProduct";

const BrandProducts = ({
  brandIndex,
  products,
  orderDetail,
  setOrderDetail,
}) => {
  return (
    <div className="w-full" key={brandIndex}>
      <div className="rounded-[0.375rem] p-5 w-full border-2 border-[#e6e6e6]">
        <h1 className="font-semibold text-[1.125rem] mb-4">
          {products[0]?.product_id?.modjen_brand_name?.toUpperCase()} - Delivery
          Status
        </h1>
        {products?.map((product, productIndex) => (
          <EditProduct
            key={productIndex}
            {...{
              orderDetail,
              product,
              setOrderDetail,
              showClear: products.length > 1,
            }}
          />
        ))}
        <div className="bg-[#e6e6e6] rounded-[0.375rem] p-5 flex justify-between w-full">
          <h1 className="font-semibold">
            {products[0]?.product_id?.modjen_brand_name?.toUpperCase()} -
            (Delivery Charges)
          </h1>
          <h1 className="font-semibold">
            <h1 className="font-semibold">
              Rs. {orderDetail.delivery_charges}
            </h1>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default BrandProducts;
