import { TableCell, TableRow } from "@mui/material";
import {
  deleteSubCategoryEndPoint,
  updateSubCategoryEndPoint,
} from "api/endpoints/sub-category";
import { toastError, toastSuccess } from "api/integration/toast";
import { HTTP_STATUS_CODES } from "constants";
import { useUserContext } from "context";
import React, { useState } from "react";
import { dateFormatter } from "utils/numberFormatter/date";
import ActionDropdown from "views/SubCategory/components/ActionDropdown";

const SubCategoryTableRow = ({ row }) => {
  const { token, onGetAllSubCategory, setLoading } = useUserContext();
  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [categoryName, setCategoryName] = useState(row.name);

  const onUpdateSubCategory = async () => {
    try {
      const response = await updateSubCategoryEndPoint(
        token,
        { name: categoryName },
        row.id
      );
      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        setLoading(true);
        setOpenModal(false);
        setIsEditing(false);
        onGetAllSubCategory();
        toastSuccess("Action Performed Successfully");
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const onDeleteSubCategory = async () => {
    try {
      const response = await deleteSubCategoryEndPoint(token, row.id);
      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        onGetAllSubCategory();
        setLoading(true);
        toastSuccess("Action Performed Successfully");
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
      <TableCell sx={{ fontWeight: "700" }}>{row.id.substring(18)}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{dateFormatter(row.createdAt)}</TableCell>
      <TableCell>{dateFormatter(row.updatedAt)}</TableCell>

      <TableCell onClick={(event) => event.stopPropagation()}>
        <ActionDropdown
          openModal={openModal}
          setOpenModal={setOpenModal}
          categoryName={categoryName}
          setCategoryName={setCategoryName}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          onUpdateSubCategory={onUpdateSubCategory}
          onDeleteSubCategory={onDeleteSubCategory}
        />
      </TableCell>
    </TableRow>
  );
};

export default SubCategoryTableRow;
