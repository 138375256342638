import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, TextField } from "@mui/material";
import { updateOrderByIds } from "api/endpoints/order";
import { HTTP_STATUS_CODES } from "constants";
import { useUserContext } from "context";
import { toastError, toastSuccess } from "api/integration/toast";

import { ConvertObjectOfArraysIntoArray } from "utils/functions";
import BrandProducts from "views/Orders/components/OrdersDetail/EditOrders/BrandProducts";

const EditOrderModal = ({
  orderDetail: mainOrderState,
  setShowOrderDetailModal,
}) => {
  const [orderDetail, setOrderDetail] = useState({ ...mainOrderState });
  const { token, onGetAllOrders, setLoading } = useUserContext();

  const handleOnChangeValue = (key, value) => {
    setOrderDetail((prev) => ({
      ...prev,
      address: { ...prev.address, [key]: value },
    }));
  };

  const handleUpdateOrder = async (id) => {
    try {
      setLoading(true);
      const response = await updateOrderByIds(
        token,
        {
          ...orderDetail,
          products: ConvertObjectOfArraysIntoArray(orderDetail.brands),
          user_id: orderDetail.user_id.id,
        },
        id
      );

      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        onGetAllOrders();
        setShowOrderDetailModal(false);
        toastSuccess("Orders updated successfully");
      } else {
        toastError("Failed to updated");
      }
    } catch (error) {
      console.error("Error updating:", error);
      toastError("An error occurred while updating");
    }
  };

  return (
    <div className="fixed top-0 left-0 bg-black bg-opacity-25 h-screen w-screen flex items-center justify-center z-[99999] my-auto overflow-y-auto py-5">
      <div className="bg-white rounded-lg pt-8 pb-4 px-5 relative w-10/12 flex flex-col items-center gap-8 z-20 m-auto">
        <CancelIcon
          onClick={() => setShowOrderDetailModal(false)}
          sx={{
            position: "absolute",
            top: "5px",
            right: "5px",
            color: "#ff2f60",
            cursor: "pointer",
          }}
        />
        <h1 className="text-2xl">Edit Orders</h1>
        <div className="grid grid-cols-2 w-full gap-8">
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "pink",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#ff2f60",
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#ff2f60",
              },
            }}
            id="label"
            name="label"
            label="label"
            value={orderDetail.address.label}
            onChange={({ target }) =>
              handleOnChangeValue([target.name], target.value)
            }
          />
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "pink",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#ff2f60",
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#ff2f60",
              },
            }}
            id="address"
            name="address"
            label="address"
            value={orderDetail.address.address}
            onChange={({ target }) =>
              handleOnChangeValue([target.name], target.value)
            }
          />
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "pink",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#ff2f60",
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#ff2f60",
              },
            }}
            id="apartment"
            name="apartment"
            label="apartment"
            value={orderDetail.address.apartment}
            onChange={({ target }) =>
              handleOnChangeValue([target.name], target.value)
            }
          />
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "pink",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#ff2f60",
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#ff2f60",
              },
            }}
            id="city"
            name="city"
            label="city"
            value={orderDetail.address.city}
            onChange={({ target }) =>
              handleOnChangeValue([target.name], target.value)
            }
          />
        </div>
        {orderDetail &&
          Object.values(orderDetail.brands).map((products, brandIndex) => {
            return (
              <>
                {products.length > 0 && (
                  <BrandProducts
                    {...{ brandIndex, products, orderDetail, setOrderDetail }}
                  />
                )}
              </>
            );
          })}
        {orderDetail && (
          <div className="bg-[#ff1d52] rounded-[0.375rem] p-5 flex flex-col justify-between w-full gap-y-4">
            <div className="flex justify-between w-full">
              <h1 className="font-medium text-white">Sub-Total</h1>
              <h1 className="font-semibold text-white">
                <h1 className="font-semibold">Rs. {orderDetail.sub_total}</h1>
              </h1>
            </div>
            <div className="flex justify-between w-full">
              <h1 className="font-medium text-white">Delivery Charges</h1>
              <h1 className="font-semibold text-white">
                <h1 className="font-semibold">
                  Rs. {orderDetail.delivery_charges}
                </h1>
              </h1>
            </div>
            <hr />
            <div className="flex justify-between w-full">
              <h1 className="font-medium text-white">Total</h1>
              <h1 className="font-semibold text-white">
                <h1 className="font-semibold">Rs. {orderDetail.amount}</h1>
              </h1>
            </div>
          </div>
        )}
        <div className="flex items-center gap-2 justify-end w-full">
          <Button
            onClick={() => handleUpdateOrder(orderDetail.id)}
            variant="outlined"
            sx={{
              color: "#ff2f60",
              border: "1px solid #ff2f60",
              "&:hover": {
                border: "1px solid #ff2f60",
              },
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditOrderModal;
