export const CONTACT_COLUMNS = [
  { id: "id", label: "ID", minWidth: 170 },
  { id: "username", label: "Username", minWidth: 170 },
  {
    id: "phone_number",
    label: "Phone Number",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  { id: "subject", label: "Subject", minWidth: 170 },
  { id: "message", label: "Message", minWidth: 170 },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];
