import { callDelete, callGet, callPatch, callPost } from "api/integration/call";
import { REQ } from "constants/index";

export const getAllBrandsEndPoint = async (token) => {
  return await callGet(REQ.BRAND.GET_ALL, token);
};
export const postBrandsEndPoint = async (token, data) => {
  return await callPost(REQ.BRAND.POST, data, token);
};
export const updateBrandByIdEndPoint = async (token, data, id) => {
  return await callPatch(
    REQ.BRAND.UPDATE_BY_ID.replace(":id", id),
    data,
    token
  );
};
export const deleteBrandEndPoint = async (token, id) => {
  return await callDelete(REQ.BRAND.DELETE_BY_ID.replace(":id", id), {}, token);
};
