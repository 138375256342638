import React, { useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/material";
import { getProductByIdEndPoint } from "api/endpoints/product";
import { HTTP_STATUS_CODES } from "constants";
import { toastError } from "api/integration/toast";

const EditProduct = ({ orderDetail, product, setOrderDetail, showClear }) => {
  const [variant, setVariant] = React.useState([]);
  const onDeleteProduct = ({ product_id }) => {
    setOrderDetail((prev) => {
      const brands = prev.brands;
      const filteredArray = brands[product_id.modjen_brand_name].filter(
        (item) => item.product_id.id !== product_id.id
      );

      if (filteredArray.length > 0) {
        brands[product_id.modjen_brand_name] = filteredArray;
      } else {
        delete brands[product_id.modjen_brand_name];
      }
      const updatedProducts = Object.values(brands).flat();
      const updatedTotalPrice = calculateTotalPrice(brands);

      return {
        ...prev,
        products: updatedProducts,
        brands,
        total_quantity: updatedTotalPrice.totalQuantity,
        sub_total: updatedTotalPrice.subTotal,
        amount: updatedTotalPrice.amount,
      };
    });
  };

  const handleChangeQuantity = (product, quantityChange) => {
    setOrderDetail((prev) => {
      const brands = { ...prev.brands };
      const productToUpdate = brands[product.product_id.modjen_brand_name].find(
        (item) => item.product_id.id === product.product_id.id
      );

      if (productToUpdate) {
        if (productToUpdate.quantity + quantityChange >= 1) {
          productToUpdate.quantity += quantityChange;
        } else {
          productToUpdate.quantity = 1;
        }
      }

      const updatedTotalPrice = calculateTotalPrice(brands);

      return {
        ...prev,
        brands,
        total_quantity: updatedTotalPrice.totalQuantity,
        sub_total: updatedTotalPrice.subTotal,
        amount: updatedTotalPrice.amount,
      };
    });
  };

  const calculateTotalPrice = (brands) => {
    let totalQuantity = 0;
    let subTotal = 0;
    let amount = 0;

    Object.values(brands).forEach((brandProducts) => {
      brandProducts.forEach((product) => {
        totalQuantity += product.quantity;
        subTotal += product.quantity * product.price;
        amount += product.quantity * product.price;
      });
    });

    return { totalQuantity, subTotal, amount };
  };

  const handleChangeVariant = (e) => {
    const variant = JSON.parse(e.target.value);
    setOrderDetail((prev) => {
      const brands = { ...prev.brands };
      const selectedBrand = brands[product.product_id.modjen_brand_name].map(
        (item) => {
          if (item.product_id.id === product.product_id.id) {
            item.variant = variant.title;
            item.price = variant.price;
            item.variant_id = variant.id + "";
          }
          return item;
        }
      );

      return {
        ...prev,
        brands: {
          ...brands,
          [product.product_id.modjen_brand_name]: selectedBrand,
        },
      };
    });
  };

  useEffect(() => {
    (async () => {
      const response = await getProductByIdEndPoint(product.product_id.id);
      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        setVariant(response.data.variants);
      } else {
        toastError(`Get Product Failed`);
      }
    })();
  }, [product.product_id.id]);

  return (
    <div className="flex w-full gap-5 mb-4 relative">
      {Object.keys(orderDetail.brands).length > 1 ? (
        <ClearIcon
          onClick={() => onDeleteProduct(product)}
          sx={{
            position: "absolute",
            top: "-12px",
            right: "0px",
            cursor: "pointer",
            fontSize: "16px",
          }}
        />
      ) : (
        showClear && (
          <ClearIcon
            onClick={() => onDeleteProduct(product)}
            sx={{
              position: "absolute",
              top: "-12px",
              right: "0px",
              cursor: "pointer",
              fontSize: "16px",
            }}
          />
        )
      )}
      <div className="img-box overflow-hidden rounded min-w-[95px] product-order-image">
        <img
          alt="product-i"
          draggable="false"
          loading="lazy"
          width="115"
          height="195"
          decoding="async"
          data-img="1"
          src={
            product?.product_id?.images[0]
              ? product?.product_id?.images[0].src
              : ""
          }
          style={{ color: "transparent" }}
        />
      </div>
      <div className="flex flex-col justify-around w-full">
        <div className="flex justify-between py-2 text-sm md:text-base w-full">
          <div className="flex flex-col gap-y-2">
            <span className="font-bold">
              Product ID: {product?.product_id?.id}
            </span>
            <span className="font-bold">
              {product?.product_id?.modjen_brand_name?.toUpperCase()}
            </span>
            <span className="font-bold">{product.product_id?.handle}</span>
          </div>
          <div className="text-[#ff3060] font-medium flex flex-col gap-2 items-end">
            <select name="variant" onChange={handleChangeVariant}>
              {variant?.map((item, index) => (
                <option
                  value={JSON.stringify(item)}
                  key={"variant--option--" + index}
                  disabled={!item.available}
                >
                  {item.title}
                </option>
              ))}
            </select>

            <div>
              <Button
                variant="filled"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  "&:hover": {
                    backgroundColor: "#ff2f60",
                  },
                }}
                onClick={() => handleChangeQuantity(product, -1)}
              >
                -
              </Button>
              <Button
                variant="filled"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "0px",
                  "&:hover": {
                    backgroundColor: "#000000",
                  },
                }}
              >
                {product?.quantity}
              </Button>
              <Button
                onClick={() => handleChangeQuantity(product, 1)}
                variant="filled"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  "&:hover": {
                    backgroundColor: "#ff2f60",
                  },
                }}
              >
                +
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between">
          <span className="font-bold">
            {product?.product_id?.title.toUpperCase()}
          </span>
          <h1 className="font-semibold">Rs. {product.price}</h1>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
