import { callGet, callPatch } from "api/integration/call";
import { REQ } from "constants";

export const getAllContactsEndPoint = async (token) => {
  return await callGet(REQ.CONTACT_US.GET_ALL, token);
};

export const updateContactsByIdEndPoint = async (token, data, id) => {
  return await callPatch(
    REQ.CONTACT_US.UPDATE_CONTACT_STATUS_BY_ID.replace(":id", id).replace(
      ":status",
      data["status"]
    ),
    {},
    token
  );
};
