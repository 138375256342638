import React from "react";
import logo from "../../assets/images/logo.png";
import SignInForm from "./components/Form";
const Login = () => {
  return (
    <div className="h-screen w-full flex justify-center items-center bg-[#f4f7fe] ">
      <div className="flex flex-col items-center w-[27%] min-w-[420px]">
        <img className="w-64 max-h-full flex flex-shrink" src={logo} alt="" />
        <h1 className="leading-tight text-[#1b254b] mb-3 text-4xl font-bold w-full">
          Sign In
        </h1>
        <p className="mb-[36px] text-[#a0aec0] font-normal w-full pl-1">
          Enter your email and password to sign in!
        </p>
        <SignInForm />
      </div>
    </div>
  );
};
export default Login;
