import React, { useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import { dateFormatter } from "utils/numberFormatter/date/index.js";
import ActionDropdown from "views/Users/components/ActionDropdown";
import { deleteUserEndPoint } from "api/endpoints/user";
import { HTTP_STATUS_CODES } from "constants";
import { toastError, toastSuccess } from "api/integration/toast";
import { useUserContext } from "context";

const UsersTableRow = ({ row, handleOpenModal }) => {
  const { token, onGetAllUser, setLoading } = useUserContext();

  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const onDeleteUser = async () => {
    try {
      const response = await deleteUserEndPoint(token, row.id);
      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        setLoading(true);
        onGetAllUser();
        toastSuccess("Action Performed Successfully");
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      onClick={() => handleOpenModal(row)}
      sx={{ cursor: "pointer" }}
    >
      <TableCell sx={{ fontWeight: "700" }}>{row.id.substring(18)}</TableCell>
      <TableCell>{row.username}</TableCell>
      <TableCell>{row.phone_number}</TableCell>
      <TableCell>{row.status}</TableCell>
      <TableCell>{dateFormatter(row.createdAt)}</TableCell>
      <TableCell onClick={(event) => event.stopPropagation()}>
        <ActionDropdown
          openModal={openModal}
          setOpenModal={setOpenModal}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          onDeleteUser={onDeleteUser}
          row={row}
        />
      </TableCell>
    </TableRow>
  );
};

export default UsersTableRow;
