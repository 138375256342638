import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "@mui/material";
import { useUserContext } from "context";
import { changeOrderStatusByIdEndPoint } from "api/endpoints/order";
import { HTTP_STATUS_CODES } from "constants";
import { toastError, toastSuccess } from "api/integration/toast";

const ChangeOrderStatus = () => {
    const {
        token,
        onGetAllOrders,
        setLoading,
        modalState: data,
        setModalAndState,
    } = useUserContext();
    const [reason, setReason] = useState(null);

    const handleChangeStatus = async () => {
        try {
            const response = await changeOrderStatusByIdEndPoint(token, data.id, {
                isActive: !data.orderStatus.isActive,
                reason: reason ?? "",
            });
            if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
                setModalAndState({ modal: null, state: {} });
                setLoading(true);
                onGetAllOrders();
                toastSuccess("Change Order Status Successfully");
            }
        } catch (error) {
            toastError(error.message);
        }
    };

    return (
        <div className="fixed top-0 left-0 bg-black bg-opacity-25 min-h-screen min-w-screen flex items-center justify-center z-50 my-auto overflow-y-auto py-5 h-full w-full">
            <div className="bg-white rounded-lg pt-8 pb-6 px-16 relative flex flex-col items-center gap-8 z-20 m-auto">
                <CancelIcon
                    onClick={() => setModalAndState({ modal: null, state: {} })}
                    sx={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        color: "#ff2f60",
                        cursor: "pointer",
                    }}
                />
                <div className="text-center">
                    <h1 className="text-2xl">Change Order Status</h1>
                    <div>
                        <p>
                            Your Order Status is{" "}
                            {data.orderStatus.isActive ? "Active" : "Canceled"}
                        </p>
                        {data.orderStatus.reason ? (
                            <p>Reason: {data.orderStatus.reason}</p>
                        ) : (
                            <div className="mt-5">
                                <textarea
                                    placeholder="Type Your Reason"
                                    className="w-full border p-2 resize-none rounded-sm ring-0 focus-visible:shadow-none focus-visible:outline-0 "
                                    value={reason}
                                    onChange={({ target }) => setReason(target.value)}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex items-center gap-x-5 w-full">
                    <Button
                        onClick={handleChangeStatus}
                        disabled={data.orderStatus.isActive ? !reason : false}
                        sx={{
                            width: "100%",
                            color: "white",
                            border: "1px solid #ff2f60",
                            backgroundColor: "#ff2f60",
                            "&:hover": {
                                border: "1px solid #ff2f60",
                                backgroundColor: "#ff2f60",
                            },
                            "&:disabled": {
                                backgroundColor: "gray",
                                color: "#fff",
                                border: "1px solid gray",
                                cursor: "not-allowed",
                            },
                        }}
                        variant="outline"
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => setModalAndState({ modal: null, state: {} })}
                        sx={{
                            width: "100%",
                            color: "#ff2f60",
                            border: "1px solid #ff2f60",
                            "&:hover": {
                                border: "1px solid #ff2f60",
                            },
                        }}
                        variant="outline"
                    >
                        No
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ChangeOrderStatus;
