import { capitalizeString } from "api/integration/functions";
import React from "react";

const ManualCheck = ({ manualCheck, productId, onUpdateData }) => {
  const manualCheckData = ["not_checked", "wrong", "final"];

  return (
    <div className="mb-3 2xl:mb-5 w-auto rounded-2xl bg-[#ffffff] shadow-xl p-3 h-[100px] gap-x-8">
      <h3 className="text-base md:text-lg text-heading font-semibold mb-2.5 capitalize">
        Manual Check
      </h3>
      <div className="colors flex flex-wrap gap-3">
        {manualCheckData.map((item, i) => (
          <button
            key={i}
            className={`cursor-pointer rounded border border-gray-100 px-3 py-1.5 relative font-regular text-[12px] transition duration-200 ease-in-out hover:border-[#ff1d52] min-h-[35px] min-w-[35px] shadow-lg ${
              manualCheck.toLowerCase() === item.toLowerCase()
                ? "bg-[#ff1d52] text-white font-semibold"
                : ""
            }`}
            onClick={() =>
              onUpdateData(productId, "manual_check", item.toLowerCase())
            }
          >
            {capitalizeString(item.replace(/_/g, " "))}
          </button>
        ))}
      </div>
      {/* <div className="border-t border-gray-300 mt-6 mb-0"></div> */}
    </div>
  );
};

export default ManualCheck;
