import Layout from "layout";
import { useEffect, useState } from "react";
import { URL } from "constants";
import {
  getAllproductsEndpoint,
  updateProductByIdEndPoint,
} from "api/endpoints/product";
import { useUserContext } from "context";
import { HTTP_STATUS_CODES, MANUAL_CHECK } from "constants/index";
import { toastError, toastSuccess } from "api/integration/toast";
import ProductCategory from "./components/ProductCategory";
import Category from "./components/Category";
import Age from "./components/Age";
import ManualCheck from "./components/ManualCheck";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import "react-photo-view/dist/react-photo-view.css";
import Tag from "./components/Tag";
import CloseIcon from "@mui/icons-material/Close";
import ModalPortal from "components/Portal";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import imageNotFound from "../../assets/images/icon-image-not-found-free-vector.jpg";
import MultiSelectionBox from "components/MultiSelectionBox";
import Loader from "components/Loader";

const Wrong = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { token, setLoading } = useUserContext();
  const [products, setProducts] = useState([]);
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const [updatedProduct, setUpdatedProduct] = useState(null);
  const { onGetAllSubCategory, subCategoryData } = useUserContext();
  const [selectedId, setSelectedId] = useState(null);
  const [count, setCount] = useState();
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onGetAllSubCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllProducts = async () => {
    const response = await getAllproductsEndpoint(
      token,
      pageNumber,
      MANUAL_CHECK.WRONG
    );
    if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
      const product = response.data?.data[0];
      setSelectedSubCategories(product.sub_category);
      setProducts([product]);
      setCurrentProductIndex(0);
      setUpdatedProduct(null);
      setCount(response.data.count);
    }
  };

  useEffect(() => {
    getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const nextPage = () => {
    if (currentProductIndex < products.length - 1) {
      setCurrentProductIndex((prevIndex) => prevIndex + 1);
    } else {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
    setIsButtonDisabled(true);
    setIsLoading(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
      setIsLoading(false);
    }, 5000);
  };

  const previousPage = () => {
    if (currentProductIndex > 0) {
      setCurrentProductIndex((prevIndex) => prevIndex - 1);
    } else if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
    setIsButtonDisabled(true);
    setIsLoading(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
      setIsLoading(false);
    }, 5000);
  };

  const updateProduct = async (product) => {
    const response = await updateProductByIdEndPoint(
      token,
      product,
      product.id
    );
    if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
      setLoading(true);
      toastSuccess("Product Updated Successfully");
    } else {
      toastError("Updation Failed");
    }
  };

  const onUpdateData = (id, key, value) => {
    const updatedData = products.map((item) => {
      if (item.id === id) {
        return { ...item, [key]: value };
      }
      return item;
    });

    setProducts(updatedData);
  };

  const onSave = async () => {
    const product = {
      ...products[currentProductIndex],
      ...updatedProduct,
      sub_category: selectedSubCategories,
    };

    await updateProduct(product);

    setProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts[currentProductIndex] = product;
      return updatedProducts;
    });

    setUpdatedProduct(null);
    getAllProducts();
  };

  const onModelShow = (id) => {
    setSelectedId(id);
    var showPortal = document.getElementsByClassName("showPortal");
    for (var i = 0; i < showPortal.length; i++) {
      showPortal[i].style.display = "block";
    }
    document.querySelector("#portal").classList.add("portals");
  };

  const onCross = () => {
    let hide = document.getElementById("hide");
    hide.style.display = "none";
    document.querySelector("#portal").classList.remove("portals");
  };

  const onSelectedSubCategories = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedSubCategories(value);
  };

  return (
    <>
      <Layout showTopBar={true} route={URL.WRONG.BASE}>
        <div className="mt-0">
          {products &&
            products.length > 0 &&
            products.map((product, index) => {
              if (index === currentProductIndex) {
                const displayedImages = product.images.slice(0, 4);
                return (
                  <>
                    <div
                      key={product.id}
                      className="px-4 md:px-14 lg:px-0 flex flex-col flex-wrap items-start"
                    >
                      <div className="flex flex-wrap sm:flex-wrap md:flex md:flex-nowrap gap-8 w-full mb-4">
                        {displayedImages.length > 4 ? (
                          <Swiper
                            slidesPerView={4}
                            spaceBetween={30}
                            cssMode={true}
                            navigation={true}
                            mousewheel={true}
                            keyboard={true}
                            pagination={{
                              clickable: true,
                            }}
                            modules={[
                              Navigation,
                              Pagination,
                              Mousewheel,
                              Keyboard,
                            ]}
                            className="mySwiper"
                          >
                            {displayedImages.map((image) => (
                              <SwiperSlide>
                                <div className="w-auto h-auto transition duration-150 ease-in hover:opacity-90 image-magnifier cursor-pointer">
                                  <img
                                    src={image.src}
                                    alt=""
                                    className="h-[240px] 2xl:h-[325px]"
                                  />
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        ) : displayedImages.length > 0 ? (
                          displayedImages.map((image) => (
                            <PhotoProvider key={image.id}>
                              <PhotoView src={image.src}>
                                <div className="transition duration-150 ease-in hover:opacity-90 image-magnifier cursor-pointer">
                                  <img
                                    src={image.src}
                                    alt=""
                                    className="h-[240px] 2xl:h-[325px] w-[100%] object-cover"
                                  />
                                </div>
                              </PhotoView>
                            </PhotoProvider>
                          ))
                        ) : (
                          <PhotoProvider key={imageNotFound}>
                            <PhotoView src={imageNotFound}>
                              <div className="transition duration-150 ease-in hover:opacity-90 image-magnifier cursor-pointer">
                                <img
                                  src={imageNotFound}
                                  alt=""
                                  className="h-[240px] 2xl:h-[325px] w-[100%] object-cover"
                                />
                              </div>
                            </PhotoView>
                          </PhotoProvider>
                        )}
                      </div>

                      <div className="lg:pt-0 md:gap-x-8 pt-9 w-[97%] ml-[9px] flex flex-wrap justify-between">
                        <div className="pb-2 md:pb-4 mb-3 2xl:mb-5 border-b min-w-fit w-80 min-h-28 rounded-2xl bg-[#ffffff] shadow-xl p-3">
                          <h2 className="text-secondary text-lg md:text-xl lg:text-1xl 2xl:text-1xl font-bold hover:text-black capitalize">
                            {product.title}
                          </h2>
                          <h3 className="text-2xl capitalize text-secondary my-1">
                            {product.modjen_brand_name}
                          </h3>
                          <div className="flex items-center gap-2">
                            <div>{`Rs. ${product.general_price}`}</div>
                          </div>
                          {/* <div className="border-t border-gray-300 mb-0"></div> */}
                        </div>
                        <div className="w-60 min-w-fit h-[100px] mb-3 2xl:mb-5 rounded-2xl bg-[#ffffff] shadow-xl p-3">
                          <div className="w-full flex justify-between">
                            <h3 className="text-base md:text-lg text-heading font-semibold mb-2.5 capitalize">
                              Handle
                            </h3>
                            {product && product.body_html !== "" ? (
                              <button
                                onClick={() => onModelShow(product.id)}
                                className="cursor-pointer mb-2 rounded border border-gray-100  px-1 py-0 relative font-regular text-[10px] transition duration-200 ease-in-out hover:border-[#ff1d52] min-h-[35px] min-w-[35px] shadow-lg bg-[#ff1d52] text-white font-semibold"
                              >
                                Description
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="colors flex flex-wrap gap-3 items-center">
                            {product.handle}
                          </div>
                          {/* <div className="border-t border-gray-300 mt-6 mb-0"></div> */}
                        </div>
                        <ProductCategory
                          productId={product.id}
                          eastern={product.eastern}
                          stitched={product.stitched}
                          onUpdateData={onUpdateData}
                        />
                        <Age
                          productId={product.id}
                          age={product.age}
                          onUpdateData={onUpdateData}
                        />

                        <Category
                          productId={product.id}
                          category={product.category}
                          onUpdateData={onUpdateData}
                        />
                        <div className="w-72 mb-3 2xl:mb-5 rounded-2xl bg-[#ffffff] shadow-xl p-3 h-auto">
                          <h3 className="text-base md:text-lg mb-2.5 text-heading font-semibold capitalize">
                            Sub Category
                          </h3>
                          <div className="colors flex flex-wrap gap-3">
                            <MultiSelectionBox
                              label={"Sub Category"}
                              options={subCategoryData.data}
                              selectedValue={selectedSubCategories}
                              setSelectedValue={setSelectedSubCategories}
                              onChange={onSelectedSubCategories}
                            />
                          </div>
                        </div>
                        <ManualCheck
                          productId={product.id}
                          manualCheck={product.manual_check}
                          onUpdateData={onUpdateData}
                        />

                        <div className="w-auto mb-3 2xl:mb-5 rounded-2xl bg-[#ffffff] shadow-xl p-3 min-h-[100px]">
                          <h3 className="text-base md:text-lg text-heading font-semibold capitalize mb-2.5">
                            Tag
                          </h3>
                          <div className="colors flex flex-wrap gap-3 w-auto">
                            <Tag tag={product.tags} />
                          </div>
                          {/* <div className="border-t border-gray-300 mt-6"></div> */}
                        </div>
                        <div className="flex w-full justify-between">
                          <button
                            onClick={onSave}
                            className="leading-4 cursor-pointer ease-in-out font-outfit text-center border-0 border-transparent rounded-md placeholder-white focus-visible:outline-none focus:outline-none  hover:-translate-y-1 hover:shadow-btnShadow transition-all duration-500 h-11 md:h-12 px-5 bg-[#ff1d52] text-white py-2 transform-none normal-case hover:text-white  hover:shadow-cart w-[75%] md:w-[80%] lg:w-[86%] font-regular"
                          >
                            Save
                          </button>
                          <div className="flex gap-4 justify-endk">
                            <button
                              disabled={isButtonDisabled}
                              onClick={previousPage}
                            >
                              <ArrowBackIcon sx={{ color: "#ff0000" }} />
                            </button>
                            <p className="mt-3">
                              {pageNumber} of {count}
                            </p>
                            <button
                              disabled={isButtonDisabled}
                              onClick={nextPage}
                            >
                              <ArrowForwardIcon sx={{ color: "#ff0000" }} />
                            </button>
                          </div>
                        </div>
                        <div>{product.pageNumber}</div>
                      </div>
                    </div>
                    <ModalPortal>
                      {selectedId === product.id && (
                        <div
                          id="hide"
                          className="showPortal relative w-[50%] rounded-2xl bg-white z-50 transition-all duration-150 ease-in-out p-5"
                        >
                          <CloseIcon
                            onClick={() => onCross()}
                            sx={{
                              position: "absolute",
                              right: "20px",
                              cursor: "pointer",
                              color: "#ff1d52",
                            }}
                          >
                            X
                          </CloseIcon>
                          <div
                            className="custom-html-content"
                            dangerouslySetInnerHTML={{
                              __html: product.body_html,
                            }}
                          ></div>
                        </div>
                      )}
                    </ModalPortal>
                  </>
                );
              }
              return null;
            })}
          {isLoading && <Loader />}
        </div>
      </Layout>
    </>
  );
};

export default Wrong;
