import { callDelete, callGet, callPatch } from "api/integration/call";
import { REQ } from "constants/index";

export const getAllproductsEndpoint = async (
  token,
  pageNumber,
  manual_check
) => {
  return callGet(
    `${REQ.PRODUCT.GET_ALL_MANUAL_CHECK}?manual-check=${manual_check}&page=${pageNumber}&limit=1`,
    token
  );
};

export const getAllproductsDataEndpoint = async (
  token,
  pageNumber,
  rowsPerPage
) => {
  return callGet(
    `${REQ.PRODUCT.GET_ALL}?page=${pageNumber}&limit=${rowsPerPage}`,
    token
  );
};

export const updateProductByIdEndPoint = async (token, data, id) => {
  return callPatch(
    REQ.PRODUCT.UPDATE_PRODUCT_BY_ID.replace(":id", id),
    data,
    token
  );
};
export const getProductByIdEndPoint = async (id) => {
  return callGet(
    REQ.PRODUCT.UPDATE_PRODUCT_BY_ID.replace(":id", id)
  );
};




export const deleteProductEndPoint = async (token, id) => {
  return await callDelete(
    REQ.PRODUCT.DELETE_BY_ID.replace(":id", id),
    {},
    token
  );
};
