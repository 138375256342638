import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import "./index.scss";
import ClearIcon from "@mui/icons-material/Clear";

const MultiSelectionBox = ({
  options,
  selectedValue = [],
  setSelectedValue,
  label,
  className,
  onChange,
}) => {
  const [open, setOpen] = useState(false);

  const handleDelete = (event, value) => {
    const filterSelected = selectedValue.filter((item) => item !== value);
    setSelectedValue(filterSelected);
  };

  const handleClose = (e) => {
    if (e.currentTarget !== e.target) return;
    setOpen(false);
  };

  const handleOpen = (e) => {
    if (e.currentTarget !== e.target) return;
    setOpen(true);
  };

  return (
    <FormControl className={`multiSelectionBox ${className}`}>
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        className={`${selectedValue.length > 0 ? "add-padding" : ""}`}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={selectedValue}
        onChange={onChange}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={(selected) => (
          <Box className="renderBox">
            {selected.map((item, i) => {
              return options.map((opt) => {
                return item === opt.id ? (
                  <Chip
                    key={i}
                    label={opt.name}
                    onDelete={(event) => handleDelete(event, opt.id)}
                    deleteIcon={<ClearIcon />}
                  />
                ) : (
                  ""
                );
              });
            })}
          </Box>
        )}
      >
        {options.map((item, i) => (
          <MenuItem key={i} value={item.id} sx={{ fontSize: 14 }} onClick={handleClose}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectionBox;
