export const USERS_COLUMNS = [
    { id: "id", label: "ID", minWidth: 100 },
    { id: "username", label: "Username", minWidth: 170 },
    {
      id: "phone_number",
      label: "Phone Number",
      minWidth: 170,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "status",
      label: "Status",
      minWidth: 170,
      align: "left",
    },
    {
      id: "createdAt",
      label: "Date",
      minWidth: 170,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "action",
      label: "Action",
      minWidth: 170,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];