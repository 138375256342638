import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import noData from "../../../../assets/images/no-data.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import {
  deleteProductEndPoint,
  getAllproductsDataEndpoint,
} from "api/endpoints/product";
import { HTTP_STATUS_CODES } from "constants/index";
import { useUserContext } from "context";
import { useEffect } from "react";
import { toastError, toastSuccess } from "api/integration/toast";
import { capitalizeString } from "api/integration/functions";
import Loader from "components/Loader";

const columns = [
  { id: "id", label: "ID", minWidth: 100 },
  { id: "handle", label: "Handle", minWidth: 100 },
  { id: "image", label: "Image", minWidth: 170 },
  { id: "title", label: "Title", minWidth: 170, align: "left" },
  { id: "category", label: "Category", minWidth: 170, align: "left" },
  { id: "deleteBrand", label: "Action", minWidth: 170, align: "left" },
];

export default function ProductTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const { token, setLoading } = useUserContext();
  const [products, setProducts] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [productPage, setProductPage] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getAllProducts = async () => {
      try {
        const response = await getAllproductsDataEndpoint(
          token,
          pageNumber,
          rowsPerPage
        );
        if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
          setLoading(true);
          setProducts(response.data.data);
          setProductCount(response.data.count);
          setProductPage(response.data.totalPage);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, pageNumber, rowsPerPage]);

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPageNumber = Math.floor((page * rowsPerPage) / newRowsPerPage) + 1;

    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setPageNumber(newPageNumber);
  };

  const handleChangePage = async (event, newPage) => {
    const totalPages = productPage;

    if (newPage >= 0 && newPage < totalPages) {
      setPage(newPage);
      setPageNumber(newPage + 1);

      try {
        const response = await getAllproductsDataEndpoint(
          token,
          newPage + 1,
          rowsPerPage
        );
        if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
          setProducts(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }
    setIsButtonDisabled(true);
    setIsLoading(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
      setIsLoading(false);
    }, 2000);
  };

  const onDeleteProduct = async (productId) => {
    try {
      const response = await deleteProductEndPoint(token, productId);
      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        const updatedProducts = products.filter(
          (product) => product.id !== productId
        );
        setLoading(true);
        setProducts(updatedProducts);
        toastSuccess("Product deleted successfully");
      }
    } catch (error) {
      toastError("Error deleting product");
    }
  };

  return (
    <>
      <div className="mb-5 mt-3 font-bold">
        Total Products: {productCount}
      </div>
      <Paper sx={{ width: "100%", boxShadow: "none" }}>
        <TableContainer
          sx={{
            "&::-webkit-scrollbar": {
              width: 4,
              height: 4,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "white",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#FF1D52",
              borderRadius: 2,
            },
            height: "67vh",
          }}
        >
          {products.length ? (
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ fontWeight: "bold", fontSize: "18px" }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      sx={{ fontWeight: "bold", fontSize: 15 }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((row) => {
                  const displayedImage = row.images.slice(0, 1);
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell sx={{ fontWeight: "700" }}>
                        {row._id.substring(18)}
                      </TableCell>
                      <TableCell>{row.handle}</TableCell>

                      {displayedImage && displayedImage.length > 0 ? (
                        displayedImage.map((image, index) => (
                          <TableCell key={index}>
                            <img
                              src={image.src}
                              alt={row.title}
                              className="w-20 h-24 object-contain rounded-sm"
                            />
                          </TableCell>
                        ))
                      ) : (
                        <TableCell></TableCell>
                      )}
                      <TableCell>{capitalizeString(row.title)}</TableCell>
                      <TableCell>{capitalizeString(row.category)}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => onDeleteProduct(row.id)}
                        >
                          <DeleteIcon sx={{ color: "error" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <div className="flex item-center justify-center items-center h-full flex-col">
              <img src={noData} className="h-16 w-16" alt="No data" />
              <span>No data available.</span>
            </div>
          )}
        </TableContainer>
        <TablePagination
          sx={{
            color: "black",
            "& .MuiTablePagination-actions": {
              color: "#ff2f60",
            },
          }}
          SelectProps={{
            disabled: isButtonDisabled,
          }}
          backIconButtonProps={
            isButtonDisabled
              ? {
                disabled: isButtonDisabled,
              }
              : undefined
          }
          nextIconButtonProps={
            isButtonDisabled
              ? {
                disabled: isButtonDisabled,
              }
              : undefined
          }
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={productCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {isLoading && <Loader />}
    </>
  );
}
