import axios from "axios";

export const callEndpoint = async (url, method, data, token) => {
  try {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      accept: "application/json",
      audience: window.location.origin,
      "Content-Type": "application/json",
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const res = await axios({
      url: url,
      method: method,
      data: data,
      headers: headers,
    });

    return {
      data: res.data.data,
      message: res.data.message,
      statusCode: res.data.status,
    };
  } catch (error) {
    if (error.response) {
      return {
        message: error.response.data.message,
        statusCode: error.response.status,
      };
    }
  }
};

export const callDelete = async (url, data, token) => {
  return callEndpoint(url, "delete", data, token);
};

export const callGet = async (url, token) => {
  return callEndpoint(url, "get", {}, token);
};

export const callPost = async (url, data, token) => {
  return callEndpoint(url, "post", data, token);
};

export const callPut = async (url, data, token) => {
  return callEndpoint(url, "put", data, token);
};

export const callPatch = async (url, data, token) => {
  return callEndpoint(url, "patch", data, token);
};
