import React, { useState } from "react";
import DashboardCard from "../DashboardCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import { numberFormatter } from "utils/numberFormatter";

const buttonData = [
  { label: "All", value: "all_Data" },
  { label: "Last Week ", value: "last_Week_Data" },
  { label: "Last Month", value: "last_Month_Data" },
];

const AnalyticsScreen = ({ data = {} }) => {
  const [active, setActive] = useState(buttonData[0].value);
  return (
    <div className="flex w-full flex-col mb-8 md:mb-14 gap-2">
      <div className="flex gap-2 mb-5">
        {buttonData?.map((item) => {
          return (
            <button
              className={`btn p-2  rounded-md border border-[#ff2f60] w-[120px] whitespace-nowrap ${active === item.value
                ? "bg-[#ff2f60] text-white"
                : "bg-white text-[#ff2f60] hover:bg-[#ff2f60] hover:text-white"
                }`}
              onClick={() => setActive(item.value)}
            >
              {item.label}
            </button>
          );
        })}
      </div>
      <div className="flex w-full justify-between">
        {Object.entries(data[active] ?? {})?.map(([key_2, val], i) => {
          return (
            <div
              key={"Dashboard-report--item" + i}
              className="w-[30%] flex flex-col gap-3"
            >
              <DashboardCard
                title={`${key_2} Earnings`}
                value={`Rs. ${numberFormatter(val.sale)}`}
                icon={
                  <AttachMoneyIcon
                    sx={{ color: "#ff2f60", fontSize: "35px" }}
                  />
                }
              />
              <DashboardCard
                title={`${key_2} Orders`}
                value={`${val.count}`}
                icon={
                  <BarChartIcon sx={{ color: "#ff2f60", fontSize: "35px" }} />
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnalyticsScreen;
