import { capitalizeString } from "api/integration/functions";
import React from "react";

const Category = ({ category, productId, onUpdateData }) => {
  const CategoryData = ["topwear", "bottomwear", "footwear", "accessories"];

  return (
    <div className="h-[100px] mb-3 2xl:mb-5 rounded-2xl bg-[#ffffff] shadow-xl p-3 gap-x-8">
      <h3 className="text-base md:text-lg text-heading font-semibold mb-2.5 capitalize">
        Category
      </h3>
      <div className="colors flex flex-wrap gap-3">
        {CategoryData.map((item, i) => (
          <button
            key={i}
            className={`me-0 cursor-pointer rounded border border-gray-100 px-3 py-1.5 relative font-regular text-[12px] transition duration-200 ease-in-out hover:border-[#ff1d52] min-h-[35px] min-w-[35px] shadow-lg ${
              (category ?? "").toLowerCase() === item.toLowerCase()
                ? "bg-[#ff1d52] text-white font-semibold"
                : ""
            }`}
            onClick={() =>
              onUpdateData(productId, "category", item.toLowerCase())
            }
          >
            {capitalizeString(item)}
          </button>
        ))}
      </div>
      {/* <div className="border-t border-gray-300 mt-6 mb-0"></div> */}
    </div>
  );
};

export default Category;
