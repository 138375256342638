import React from "react";
import logo from "assets/images/logo.png";

const ProductDetail = ({ product, index }) => {
  return (
    <React.Fragment key={index}>
      <div className="flex w-full gap-5 mb-4">
        <div className="img-box overflow-hidden rounded min-w-[95px] product-order-image flex justify-center items-center">
          <img
            alt="product_image"
            draggable="false"
            loading="lazy"
            width="115"
            height="195"
            decoding="async"
            data-img="1"
            className="object-cover"
            src={
              product?.product_id?.images[0]
                ? product?.product_id?.images[0].src
                : logo
            }
            style={{ color: "transparent" }}
          />
        </div>
        <div className="flex flex-col justify-around w-full">
          <div className="flex justify-between py-2 text-sm md:text-base w-full">
            <div className="flex flex-col gap-y-2">
              <span className="font-bold">
                Product ID: {product?.product_id?.id}
              </span>
              <span className="font-bold">
                {product?.product_id?.modjen_brand_name?.toUpperCase() ?? "No Brand Name"}
              </span>
              <span className="font-bold">{product.product_id?.handle ?? "No Handle"}</span>
            </div>
            <h1 className="text-[#ff3060] font-medium">
              {product?.variant}{" "}
              <span className="text-[#737373] font-normal">| Qty:</span>{" "}
              <span className="text-black font-bold">{product?.quantity}</span>
            </h1>
          </div>
          <div className="w-full flex justify-between">
            <span className="font-bold">
              {product?.product_id?.title.toUpperCase() ?? "No Title - Product Deleted"}
            </span>
            <h1 className="font-semibold">Rs. {product.price}</h1>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductDetail;
