import Layout from "layout";
import React from "react";
import { Button } from "@mui/material";
import Searchbar from "components/Searchbar";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SubCategoryTable from "./components/SubCategoryTable";
import AddSubCategoryModal from "./components/AddSubCategoryModal";
import { URL } from "constants";
import { useEffect } from "react";
import { useUserContext } from "context/index.js";
import { postSubCategoryEndPoint } from "api/endpoints/sub-category";
import { HTTP_STATUS_CODES } from "constants";
import { toastError, toastSuccess } from "api/integration/toast";

const SubCategory = () => {
  const {
    token,
    subCategoryData,
    setSubCategoryData,
    onGetAllSubCategory,
    setLoading,
  } = useUserContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  var isEditing = false;

  useEffect(() => {
    onGetAllSubCategory();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubCategoryModalClose = () => {
    setIsModalOpen(false);
  };
  const onSubCategoryModalOpen = () => {
    setIsModalOpen((prev) => !prev);
  };

  const onSearchSubCategory = (value) => {
    if (value === "") {
      setSubCategoryData({
        ...subCategoryData,
        filterData: subCategoryData.data,
      });
    } else {
      const updatedList = subCategoryData.data.filter((x) => {
        const userName = x.name ? x.name.toLowerCase() : "";
        const id = x.id ? x.id.toLowerCase() : "";

        return (
          userName.includes(value.toLowerCase()) ||
          id.includes(value.toLowerCase())
        );
      });
      setSubCategoryData({
        ...subCategoryData,
        filterData: updatedList,
      });
    }
  };

  const onCreateSubCategory = async () => {
    try {
      const response = await postSubCategoryEndPoint(token, {
        name: categoryName,
      });

      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        setLoading(true);
        setCategoryName("");
        onSubCategoryModalClose();
        onGetAllSubCategory();
        toastSuccess("Action Performed Successfully");
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const onUpdateSubCategory = () => {};

  return (
    <Layout route={URL.SUBCATEGORY.BASE}>
      <Searchbar onSearchChange={onSearchSubCategory} />
      <div className="flex justify-end my-3">
        <Button
          onClick={onSubCategoryModalOpen}
          sx={{
            background: "#FF2F60",
            color: "white",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            boxShadow: 1,
            "&:hover": {
              background: "#FF005C",
              boxShadow: 3,
            },
          }}
        >
          <AddIcon sx={{ fontSize: "20px" }} />
          Create new SubCategory
        </Button>
      </div>
      <SubCategoryTable
        filterData={subCategoryData.filterData}
      />
      <AddSubCategoryModal
        onCreateSubCategory={onCreateSubCategory}
        onUpdateSubCategory={onUpdateSubCategory}
        open={isModalOpen}
        onClose={onSubCategoryModalClose}
        isEditing={isEditing}
        categoryName={categoryName}
        setCategoryName={setCategoryName}
      />
    </Layout>
  );
};

export default SubCategory;
